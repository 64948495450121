import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import entities, { StaticMachineryT } from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { useTranslation } from 'react-i18next';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { getOperationFormApi } from '../../api/getOperationFormApi';
import { ShallowWorkFormBodyT } from '../../api/operationFormApi.types';
import { SHALLOW_WORK_FORM_INPUT_NAMES, shallowWorkFormSchema } from '../../schema/shallowWorkFormSchema';
import { useOperationFormDefaultValues } from '../../hooks/useOperationFormDefaultValues';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';

export const useOperationShallowWorkMachineForm = () => {
    const { t } = useTranslation();
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();

    // Get static machinery items
    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: fieldCropId,
        farmSeasonId: seasonId,
    });
    const operation = operationState.getById(operationId);
    const operationTypeId = operation?.operation_type_id;
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { staticMachineryState } = entities.staticMachinery.useState({
        country_id: currentFarm?.country_id,
    });
    const staticMachineryLoading = staticMachineryState.isLoading;
    const staticMachineryItems = staticMachineryState.getByOperationTypeId(operationTypeId);

    const { computeApiDataToMatchSchema, formApiData, isFormLoading, refetchFormData } =
        useOperationFormDefaultValues('shallow-work');

    // Form options
    const machineryOptions = staticMachineryItems
        .map((machinery) => ({
            value: machinery.id,
            label: t(`db.machineries.${machinery.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const methods = useForm<typeof shallowWorkFormSchema.schema>({
        schema: shallowWorkFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(formApiData),
        displaySnackbarOnSchemaError: true,
    });

    const [updateShallowWorkMachine] = getOperationFormApi('shallow-work').useUpdate();

    const { watch, handleSubmit, setValue, reset, getValues } = methods;

    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    // Form callbacks
    const onSubmit = useStableDebounce(
        handleSubmit(async (data: ShallowWorkFormBodyT) => {
            try {
                await updateShallowWorkMachine({
                    farmSeasonId: seasonId,
                    fieldCropId: fieldCropId,
                    operationId: operationId,
                    body: {
                        ...data,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    const selectedMachineryId = watch(SHALLOW_WORK_FORM_INPUT_NAMES.MACHINERY_ID);
    const selectedMachinery: StaticMachineryT | undefined = staticMachineryItems.find(
        (machinery) => machinery.id === selectedMachineryId,
    );

    /**
     * set the default values for the new machinery
     */
    const changeMachineryRelatedInputs = (newMachineryId: number) => {
        const selectedMachinery: StaticMachineryT | undefined = staticMachineryItems.find(
            (machinery) => machinery.id === newMachineryId,
        );

        if (!selectedMachinery) {
            return;
        }

        setValue(SHALLOW_WORK_FORM_INPUT_NAMES.DEPTH, selectedMachinery.depth);
        if (selectedMachinery.slug !== STATIC_MACHINERY_SLUGS.STRIP_TILL) {
            setValue(SHALLOW_WORK_FORM_INPUT_NAMES.SOIL_WORK_WIDTH, null);
            setValue(SHALLOW_WORK_FORM_INPUT_NAMES.INTERROW, null);
        }
    };

    return {
        isFormLoading: isFormLoading || staticMachineryState.isLoading || currentFarmLoading || staticMachineryLoading,
        machineryOptions,
        onSubmit,
        methods,
        selectedMachinery,
        refetchFormData,
        getFormValues: getValues,
        changeMachineryRelatedInputs,
    };
};
