import { IconListFilter } from '@soil-capital/ui-kit/icons';
import { FilterType } from '@modules/encoding/modules/rotation/store/filter.slice';
import { MenuItem, Skeleton, SvgIconProps } from '@soil-capital/ui-kit/material-core';
import { Select, Tooltip } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { useFieldFiltersStyles } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldListFilters/FieldFilters.style';
import { IconCross } from '@soil-capital/ui-kit/icons';
import { SkeletonContainer } from '@shared/components/SkeletonContainer/SkeletonContainer';

export type FilterOption = {
    value: string;
    icon?: React.ComponentType<SvgIconProps>;
    translationKey: string;
    disabled?: boolean;
};

type FieldListFiltersProps = {
    value: FilterType;
    onChange: (value: FilterType) => void;
    onReset: () => void;
    filters: FilterOption[];
    isLoading?: boolean;
};

export const FieldListFilters = ({ value, onChange, onReset, filters, isLoading = false }: FieldListFiltersProps) => {
    const { t } = useTranslation();
    const { classes, cx } = useFieldFiltersStyles();
    const hasNoFilter = value === 'NO_FILTER';

    return (
        <SkeletonContainer
            isLoading={isLoading}
            Content={
                <Select
                    className={cx(!hasNoFilter && classes.selectedValue)}
                    classes={{ select: classes.select }}
                    id="field-list-filters"
                    placeholder={t('constants.filter')}
                    onChange={(e) => onChange(e.target.value as FilterType)}
                    value={value}
                    endAdornment={
                        <Tooltip className={classes.tooltip} text={t('constants.delete-filter')}>
                            <IconCross className={cx(classes.reset, hasNoFilter && classes.hide)} onClick={onReset} />
                        </Tooltip>
                    }
                    renderValue={(v) => (
                        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <IconListFilter />
                            <span className={cx(v === 'NO_FILTER' && classes.placeholder)}>
                                {t(
                                    v === 'NO_FILTER'
                                        ? 'constants.filter'
                                        : filters.find((filter) => filter.value === v)?.translationKey || '',
                                )}
                            </span>
                        </div>
                    )}
                >
                    <MenuItem sx={{ display: 'none' }} value={'NO_FILTER'} disabled>
                        {t('constants.filter')}
                    </MenuItem>
                    {filters.map((filter) => (
                        <MenuItem
                            key={filter.value}
                            className={classes.filterItem}
                            value={filter.value}
                            disabled={filter.disabled}
                        >
                            {filter.icon ? (
                                <filter.icon className={classes.filterIcon} />
                            ) : (
                                <div className={classes.filterIcon}></div>
                            )}
                            <span>{t(filter.translationKey)}</span>
                        </MenuItem>
                    ))}
                </Select>
            }
            Skeletons={<Skeleton variant="rounded" sx={{ height: '100%' }} />}
        />
    );
};
