type Item = number | { value: number | string; name: string };

type stackedBarGraphBuilderType = {
    data: { [category: string]: { [item: string]: Item } };
    xTitle?: string;
    yTitle?: string;
    hasLegend?: boolean;
    hasTooltip?: boolean;
    tooltipTitle?: string;
    tooltipUnit?: string;
    tooltipTotal?: number;
};

/**
 * Builds a configuration object for a stacked bar graph using ECharts.
 *
 * @param {object} params - The parameters for configuring the graph.
 * @param {object} params.data - The dataset for the graph.
 *   - Keys of the outer object represent categories on the x-axis (e.g., 'Xlabel').
 *   - Each inner object contains key-value pairs:
 *     - Keys are the names of the stacked items (shown in the legend).
 *     - Values are the numeric data points for each stacked item.
 * @param {string} [params.xTitle] - (Optional) Title for the x-axis.
 * @param {string} [params.yTitle] - (Optional) Title for the y-axis.
 * @param {boolean} [params.hasLegend=false] - (Optional) Whether to display a legend.
 * @param {boolean} [params.hasTooltip=false] - (Optional) Whether to enable tooltips.
 * @param {string} [params.tooltipTitle] - (Optional) Title for the tooltip.
 * @param {string} [params.tooltipUnit=''] - (Optional) Unit displayed in tooltips.
 * @returns {object} An ECharts option object for rendering a stacked bar graph.
 *
 * @example
 * // Example data input:
 * const data = {
 *   'Category1': { 'Item1': 10, 'Item2': 20, 'Item3': 30 },
 *   'Category2': { 'Item1': 15, 'Item2': 25, 'Item3': 35 },
 * };
 *
 * // Generating options for the chart:
 * const options = stackedBarGraphBuilder({
 *   data,
 *   xTitle: 'Categories',
 *   yTitle: 'Values',
 *   hasLegend: true,
 *   hasTooltip: true,
 *   tooltipTitle: 'Example Tooltip',
 *   tooltipUnit: '%',
 * });
 *
 * // Using ECharts to render the chart:
 * const chart = echarts.init(document.getElementById('chart-container'));
 * chart.setOption(options);
 */

export const stackedBarGraphBuilder = ({
    data,
    xTitle,
    yTitle,
    hasTooltip = false,
    tooltipTitle,
    tooltipUnit = '',
    hasLegend = false,
    tooltipTotal,
}: stackedBarGraphBuilderType) => {
    const categories = Object.keys(data);

    const formattedData: { [category: string]: { [item: string]: number } } = {};
    const itemNames: { [category: string]: { [item: string]: string } } = {};

    Object.keys(data).forEach((category) => {
        formattedData[category] = {};
        itemNames[category] = {};

        Object.keys(data[category]).forEach((item) => {
            const entry = data[category][item];

            if (typeof entry === 'number') {
                formattedData[category][item] = entry;
            } else {
                formattedData[category][item] = typeof entry.value === 'string' ? parseFloat(entry.value) : entry.value;
                itemNames[category][item] = entry.name;
            }
        });
    });
    const itemsSet = new Set<string>();
    categories.forEach((category) => {
        const items = Object.keys(formattedData[category]);
        items.forEach((item) => itemsSet.add(item));
    });
    const items = Array.from(itemsSet);

    const itemsNames = new Set<string>();
    categories.forEach((category) => {
        const items = Object.values(itemNames[category]);
        items.forEach((item) => itemsNames.add(item));
    });
    const names = Array.from(itemsNames);

    const maxStackValues = categories.map((category) => {
        const values = Object.values(formattedData[category]).filter((val): val is number => typeof val === 'number');
        return values.reduce((acc, val) => acc + val, 0);
    });
    const globalMaxStackValue = Math.max(...maxStackValues);
    const yAxisMax = globalMaxStackValue * 1.05;

    const lastPositiveItemPerCategory: { [category: string]: string | null } = {};
    const lastNegativeItemPerCategory: { [category: string]: string | null } = {};

    categories.forEach((category) => {
        const items = Object.keys(formattedData[category]);
        let lastPositiveItem: string | null = null;
        let lastNegativeItem: string | null = null;

        items.forEach((item) => {
            const value = formattedData[category][item];
            if (value > 0) {
                lastPositiveItem = item;
            } else if (value < 0) {
                lastNegativeItem = item;
            }
        });

        lastPositiveItemPerCategory[category] = lastPositiveItem;
        lastNegativeItemPerCategory[category] = lastNegativeItem;
    });

    const series = items.map((item) => ({
        name: item,
        type: 'bar',
        stack: 'total',
        data: categories.map((category) => {
            const value = formattedData[category][item] || 0;

            let borderRadius = [0, 0, 0, 0];
            const isLastPositive = value > 0 && item === lastPositiveItemPerCategory[category];
            const isLastNegative = value < 0 && item === lastNegativeItemPerCategory[category];

            if (isLastPositive) {
                borderRadius = [10, 10, 0, 0];
            } else if (isLastNegative) {
                borderRadius = [0, 0, 10, 10];
            }

            return {
                value,
                itemStyle: {
                    borderRadius,
                },
            };
        }),
        barWidth: '41px',
        z: 20,
    }));

    return {
        grid: {
            left: '15%',
            right: hasLegend ? '45%' : '10%',
            bottom: '10%',
            top: '10%',
        },
        tooltip: {
            show: hasTooltip,
            trigger: 'axis',
            backgroundColor: '#323F45',
            textStyle: {
                color: '#fff',
            },
            borderWidth: 0,
            appendToBody: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: function (params: any) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const total = params.reduce((sum: any, item: any) => sum + item.value, 0).toFixed(2);
                const totalPercent = 100;

                // Construction du contenu HTML
                let html = `
                    <div style="padding: 4px 8px; font-size: 12px; font-family: BrownStd-Bold, BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif; color: #FFFFFF;">
                        <div style="text-align: left; font-size: 14px; text-transform: uppercase; color: #BFCBD1; font-weight: 700; margin-bottom: 12px;">
                            ${tooltipTitle || ''}
                        </div>
                        <div style="
                        display: grid;
                        grid-template-columns: auto 50px 50px;
                        gap: 8px;
                    ">
                `;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                params.reverse().forEach((item: any) => {
                    const percent = ((item.value / total) * 100).toFixed(1);
                    html += `
                        <div style="text-align: left; display: flex; align-items: center; ">
                            <span style="display: inline-block; width: 12px; height: 12px; background-color: ${
                                item.color
                            }; border-radius: 50%; margin-right: 10px;"></span>
                            ${item.seriesName}
                        </div>
                        <div style="text-align: center;">${item.value} ${tooltipUnit || ''}</div>
                        <div style="text-align: right; color: #BFCBD1;">${percent}%</div>
                    `;
                });

                html += `
                    <div style="padding-left:22px; padding-top: 2px; text-align: left; font-size: 14px;">Total</div>
                    <div style="padding-top: 2px; text-align: center;">${tooltipTotal ?? Number(total).toFixed(2)} ${
                    tooltipUnit || ''
                }</div>
                    <div style="padding-top: 2px; text-align: right; color: #BFCBD1;">${totalPercent}%</div>
                </div>`;

                return html;
            },
        },
        legend: {
            show: hasLegend,
            orient: 'vertical',
            icon: 'circle',
            right: '20%',
            top: '10%',
            itemGap: 16,
            textStyle: {
                fontSize: 14,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
                rich: {
                    grey: {
                        color: '#98ABB4',
                        fontSize: 16,
                        fontFamily: 'BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif',
                    },
                },
            },
            selectedMode: false,
            data: items.reverse(),
            formatter: (name: string) => {
                const item = names.find((n) => n.startsWith(name));
                const value = item?.split('|')[1]?.trim();
                return value ? `${name}{grey| | ${value}}` : name;
            },
        },
        xAxis: {
            type: 'category',
            name: xTitle || '',
            nameLocation: 'middle',
            nameGap: 40,
            nameTextStyle: {
                fontSize: 14,
                fontWeight: 'bold',
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#333',
            },
            axisLine: {
                show: true,
                lineStyle: {
                    width: 2,
                    color: '#333',
                },
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                alignWithLabel: true,
                interval: 0,
                lineStyle: {
                    color: '#ccc',
                },
            },
            axisLabel: {
                interval: 0,
                show: true,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
            },
            data: categories,
            boundaryGap: true,
            z: 10,
        },
        yAxis: [
            {
                type: 'value',
                max: yAxisMax,
                name: yTitle || '',
                nameLocation: 'middle',
                nameRotate: 90,
                nameGap: 60,
                nameTextStyle: {
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                    color: '#333',
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisTick: {
                    show: true,
                    length: 7,
                    inside: false,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: true,
                    showMaxLabel: false,
                    fontWeight: 'bold',
                    margin: 12,
                    fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                },
                splitLine: {
                    show: false,
                },
                z: 10,
            },
        ],
        series,
    };
};
