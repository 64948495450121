import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useGetOrganicFertiliserQuery, useGetOrganicFertilizerPerCropQuery } from '@modules/results/api/graphs.api';
import { organicFertilizersGraphTheme } from '@modules/results/utils/theme/organicFertilizersGraphTheme';
import { stackedBarGraphBuilder } from '@modules/results/utils/stackedBarGraphBuilder';
import { SharedStateT } from '@shared/store';
import * as echarts from 'echarts';
import { useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useOrganicFertilizationGraphsData = () => {
    const productConsumptionGraphRef = useRef(null);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSeason.selectedSeasonId);
    const {
        data: organicFertiliserData,
        isLoading: isLoadingOrganicFertiliser,
        isFetching: isFetchingOrganicFertiliser,
    } = useGetOrganicFertiliserQuery({
        farmSeasonId: selectedSeasonId,
    });
    const {
        data: organicFertilizerTableData,
        isLoading: isLoadingOrganicFertilizerPerCrop,
        isFetching: isFetchingOrganicFertilizerPerCrop,
    } = useGetOrganicFertilizerPerCropQuery({ farmSeasonId: selectedSeasonId });
    const { harvestYear } = useGetCurrentHarvestYear();
    const { t } = useTranslation();

    useEffect(() => {
        if (organicFertiliserData && productConsumptionGraphRef.current) {
            const productConsumptionGraph = echarts.init(
                productConsumptionGraphRef.current,
                organicFertilizersGraphTheme,
                {
                    renderer: 'svg',
                },
            );
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear })]: organicFertiliserData.reduce((acc, item) => {
                    acc[t(item.slug)] = parseFloat(Number(item.value).toFixed(2));
                    return acc;
                }, {} as Record<string, number>),
            };
            const options = stackedBarGraphBuilder({
                data: formattedData,
                yTitle: t('results.summary.sequestration.organic-fertilization.product-consumption-y-axis-legend'),
                hasLegend: true,
                hasTooltip: true,
                tooltipTitle: t('results.summary.sequestration.organic-fertilization.product-consumption-tooltip'),
                tooltipUnit: 'T',
            });
            options.grid.right = '70%';
            options.legend.right = '40%';

            productConsumptionGraph.setOption(options);
            return () => {
                productConsumptionGraph.dispose();
            };
        }
    }, [organicFertiliserData, t, harvestYear]);

    const formatNumber = (num: number): string =>
        num % 1 === 0 ? num.toString() : parseFloat(num.toFixed(2)).toString();

    const fertilizerSlugs = [
        t('constants.crop'),
        ...(organicFertilizerTableData?.organic_fertilizer_list.map((fertilizer) => t(fertilizer.slug)) || []),
        t('constants.total'),
    ];

    const cropFertilizerMap: Record<
        number,
        {
            fertilizers: Record<string, string>;
            total_quantity: number;
        }
    > = {};

    organicFertilizerTableData?.organic_fertilizer_per_crop.forEach((item) => {
        if (!cropFertilizerMap[item.crop_id]) {
            cropFertilizerMap[item.crop_id] = { fertilizers: {}, total_quantity: 0 };
        }
        cropFertilizerMap[item.crop_id].fertilizers[item.fertilizer_id] = `${formatNumber(
            parseFloat(item.quantity),
        )} t/${t('constants.hectare-unit')}`;
        cropFertilizerMap[item.crop_id].total_quantity += parseFloat(item.total_quantity);
    });

    const formattedTableData = Object.entries(cropFertilizerMap).map(([cropId, { fertilizers, total_quantity }]) => [
        t(organicFertilizerTableData?.crop_list.find((crop) => crop.id === parseInt(cropId))?.slug || ''),
        ...(organicFertilizerTableData?.organic_fertilizer_list.map(
            (fertilizer) => fertilizers[fertilizer.id] || '0',
        ) || []),
        `${formatNumber(total_quantity)} t`,
    ]);

    const hasNoData = useMemo(
        () =>
            (organicFertiliserData?.length === 0 || !organicFertilizerTableData) &&
            !isLoadingOrganicFertiliser &&
            !isFetchingOrganicFertiliser &&
            !isLoadingOrganicFertilizerPerCrop &&
            !isFetchingOrganicFertilizerPerCrop,
        [
            isFetchingOrganicFertiliser,
            isFetchingOrganicFertilizerPerCrop,
            isLoadingOrganicFertiliser,
            isLoadingOrganicFertilizerPerCrop,
            organicFertiliserData?.length,
            organicFertilizerTableData,
        ],
    );

    return {
        hasNoData,
        productConsumptionGraphRef,
        t,
        fertilizerSlugs,
        formattedTableData,
        isLoading:
            isLoadingOrganicFertilizerPerCrop ||
            isFetchingOrganicFertilizerPerCrop ||
            isLoadingOrganicFertiliser ||
            isFetchingOrganicFertiliser,
    };
};
