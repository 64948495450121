import { FieldT } from '@shared/entities';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import { FeatureCollection } from '@turf/helpers';

export type geoJsonFeatureT = Feature<Geometry, GeoJsonProperties>;

export type MapContextT = {
    map: mapboxgl.Map | null;
    mapContainerRef: React.RefObject<HTMLDivElement>;
    setMap: React.Dispatch<React.SetStateAction<mapboxgl.Map | null>>;
    recenterMapOnPolygons: (polygonToCenter: geoJsonFeatureT[]) => void;
    centerMapOnCoordinates: (coordinates: [number, number]) => void;
    calculateCentroid: (polygonToCenter: geoJsonFeatureT[]) => [number, number] | undefined;
    zoomIn: () => void;
    zoomOut: () => void;
    fields: FieldT[] | DuplicationFieldT[] | FieldWithCropT[];
    setFields: React.Dispatch<React.SetStateAction<FieldT[] | DuplicationFieldT[] | FieldWithCropT[]>>;
    permanentFields: FieldT[] | DuplicationFieldT[] | FieldWithCropT[];
    setPermanentFields: React.Dispatch<React.SetStateAction<FieldT[] | DuplicationFieldT[] | FieldWithCropT[]>>;
    selectedFields: FieldT[] | DuplicationFieldT[] | FieldWithCropT[];
    setSelectedFields: React.Dispatch<React.SetStateAction<FieldT[] | DuplicationFieldT[] | FieldWithCropT[]>>;
    drawMode: boolean;
    setDrawMode: React.Dispatch<React.SetStateAction<boolean>>;
    editMode: boolean;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    splitMode: boolean;
    setSplitMode: React.Dispatch<React.SetStateAction<boolean>>;
    mergeMode: boolean;
    setMergeMode: React.Dispatch<React.SetStateAction<boolean>>;
    farmBoundaries: FeatureCollection | null;
    setFarmBoundaries: React.Dispatch<React.SetStateAction<FeatureCollection | null>>;
};

export type cropNamesT = {
    id: number;
    name: string;
    translation_slug?: string;
};

export type FieldWithCropT = FieldT & {
    crops: cropNamesT[];
};

export const POLYGON_SLUGS = {
    POLYGON: 'polygon',
    POLYGON_OUTLINE: 'polygon-outline',
    POLYGON_TEXT: 'polygon-text',
    POLYGON_PATTERN: 'polygon-pattern',
    PERMANENT_POLYGON: 'permanent-polygon',
    PERMANENT_POLYGON_OUTLINE: 'permanent-polygon-outline',
    PERMANENT_POLYGON_TEXT: 'permanent-polygon-text',
    PERMANENT_POLYGON_PATTERN: 'permanent-polygon-pattern',
    SELECTED_POLYGON: 'selected-polygon',
    SELECTED_POLYGON_OUTLINE: 'selected-polygon-outline',
    SELECTED_POLYGON_TEXT: 'selected-polygon-text',
} as const;
