import { useOperationDeepWorkMachineForm } from '@modules/encoding/modules/technicalItinerary/components/OperationDeepWorkMachineForm/useOperationDeepWorkMachineForm';
import OperationDeepWorkMachineFormSkeleton from '@modules/encoding/modules/technicalItinerary/components/OperationDeepWorkMachineForm/OperationDeepWorkMachineFormSkeleton';
import { NumberInput } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { useOperationDeepWorkMachineFormStyle } from '@modules/encoding/modules/technicalItinerary/components/OperationDeepWorkMachineForm/useOperationDeepWorkMachineForm.style';
import { FormProvider } from 'react-hook-form';
import { FormRow } from '@shared/components';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { ReuseOperationSelect } from '../../module/operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { useReuseOperation } from '../../module/operationDuplication/reuse/hooks/useReuseOperation';
import { DEEP_WORK_FORM_INPUT_NAMES } from '../../schema/deepWorkFormSchema';
import { FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

const OperationDeepWorkMachineForm = () => {
    const {
        selectedMachinery,
        methods,
        machineryOptions,
        watch,
        setValue,
        onSubmit,
        isFormLoading,
        getFormValues,
        refetchFormData,
        changeMachineryRelatedInputs,
    } = useOperationDeepWorkMachineForm();
    const { t } = useTranslation();

    const { classes, cx } = useOperationDeepWorkMachineFormStyle();

    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        onOperationReused: refetchFormData,
        getFormValues,
    });

    return (
        <>
            {isFormLoading ? <OperationDeepWorkMachineFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form onSubmit={onSubmit} className={cx(classes.form, isFormLoading && classes.hiddenForm)}>
                    <ReuseOperationSelect
                        fieldCropId={fieldCropId}
                        farmSeasonId={seasonId}
                        operationId={operationId}
                        onReuseOperationTriggered={handleReuseOperation}
                        disabled={isReuseOperationLoading}
                        actionLoading={isReuseOperationLoading}
                    />

                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.deep-work-machine.machine')}
                            tooltipTitleKey="encoding-technical-itinerary.deep-work-machine.machine-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.deep-work-machine.machine-tooltip-text"
                        />
                        <FormAutocomplete
                            name={DEEP_WORK_FORM_INPUT_NAMES.MACHINERY_ID}
                            placeholder={t('constants.select')}
                            options={machineryOptions}
                            disabled={isReuseOperationLoading}
                            multiple={false}
                            onChange={(value) => {
                                changeMachineryRelatedInputs(value.value);
                                onSubmit();
                            }}
                        />
                    </FormRow>

                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.deep-work-machine.passage')}
                            tooltipTitleKey="encoding-technical-itinerary.deep-work-machine.passage-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.deep-work-machine.passage-tooltip-text"
                        />
                        <NumberInput
                            min={0}
                            incrementationStep={1}
                            value={watch(DEEP_WORK_FORM_INPUT_NAMES.PASSAGE) ?? null}
                            onChange={(value) => {
                                setValue(DEEP_WORK_FORM_INPUT_NAMES.PASSAGE, value);
                                onSubmit();
                            }}
                            data-testid="passage-input"
                            style={{ width: '250px' }}
                            disabled={isReuseOperationLoading}
                        />
                    </FormRow>

                    {selectedMachinery && selectedMachinery.depth === null && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.deep-work-machine.depth')}
                                tooltipTitleKey="encoding-technical-itinerary.deep-work-machine.depth-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.deep-work-machine.depth-tooltip-text"
                            />
                            <NumberInput
                                min={0}
                                value={watch(DEEP_WORK_FORM_INPUT_NAMES.DEPTH) ?? null}
                                onChange={(value) => {
                                    setValue(DEEP_WORK_FORM_INPUT_NAMES.DEPTH, value);
                                    onSubmit();
                                }}
                                data-testid="depth-input"
                                suffix={t('constants.unit.cm')}
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}

                    {selectedMachinery?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.deep-work-machine.width')}
                                tooltipTitleKey="encoding-technical-itinerary.deep-work-machine.width-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.deep-work-machine.width-tooltip-text"
                            />
                            <NumberInput
                                min={0}
                                value={watch(DEEP_WORK_FORM_INPUT_NAMES.SOIL_WORK_WIDTH) ?? null}
                                onChange={(value) => {
                                    setValue(DEEP_WORK_FORM_INPUT_NAMES.SOIL_WORK_WIDTH, value);
                                    onSubmit();
                                }}
                                suffix={t('constants.unit.cm')}
                                data-testid="width-input"
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}
                    {selectedMachinery?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.deep-work-machine.interrow')}
                                tooltipTitleKey="encoding-technical-itinerary.deep-work-machine.interrow-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.deep-work-machine.interrow-tooltip-text"
                            />
                            <NumberInput
                                min={0}
                                value={watch(DEEP_WORK_FORM_INPUT_NAMES.INTERROW) ?? null}
                                onChange={(value) => {
                                    setValue(DEEP_WORK_FORM_INPUT_NAMES.INTERROW, value);
                                    onSubmit();
                                }}
                                suffix={t('constants.unit.cm')}
                                data-testid="interrow-input"
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}
                </form>
            </FormProvider>
        </>
    );
};

export default OperationDeepWorkMachineForm;
