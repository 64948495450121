import { useTranslation } from 'react-i18next';
import { ResultsCard } from '../ResultsCard/ResultsCard';
import { useLegumeGraphData } from './useLegumeGraph.data';
import { useLegumeGraphStyle } from './LegumeGraph.style';

export const LegumeGraph = () => {
    const { legumesData, isLoadingLegumes } = useLegumeGraphData();
    const { classes } = useLegumeGraphStyle();
    const { t } = useTranslation();

    return (
        <ResultsCard
            className={classes.whiteBg}
            data={[
                {
                    title: `${legumesData?.legume_percentage}% ${t('results.summary.sequestration.legumes.title')}`,
                    subtitle: t('results.summary.sequestration.legumes.subtitle'),
                },
            ]}
            isLoading={isLoadingLegumes}
            hasBorders
        />
    );
};
