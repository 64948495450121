import { makeStyles } from '@soil-capital/ui-kit/style';

export const useEmissionsSummaryStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.error.light,
        gap: theme.spacing(2),
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
        whiteSpace: 'nowrap',
        paddingBottom: theme.spacing(1),
    },
    emissionIcon: {
        color: theme.palette.error.main,
    },
    horizontalBar: {
        width: '100%',
        borderTop: '1px solid',
        borderColor: theme.palette.error.main,
        transform: 'translateY(2px)',
    },
    secondaryContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: theme.spacing(3),
    },
    rightContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(1),
    },
}));
