import { useCoverCropForm } from './useCoverCropForm';
import { FormProvider } from 'react-hook-form';
import { useCoverCropFormStyles } from './CoverCropForm.style';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { CoverCropSpeciesQuestion } from './ConditionalQuestions/CoverCropSpeciesQuestion';
import { CoverCropLegumeQuestion } from './ConditionalQuestions/CoverCropLegumeQuestion';
import { CoverCropSeedingMachineQuestion } from './ConditionalQuestions/CoverCropSeedingMachineQuestion';
import { CoverCropSeedingDepthQuestion } from './ConditionalQuestions/CoverCropSeedingDepthQuestion';
import { CoverCropSeedingWidthQuestion } from './ConditionalQuestions/CoverCropSeedingWidthQuestion';
import { CoverCropInterrowSpacingQuestion } from './ConditionalQuestions/CoverCropInterrowSpacingQuestion';
import { CoverCropHerbicideQuestion } from './ConditionalQuestions/CoverCropHerbicideQuestion';
import { CoverCropHerbicideQuantityQuestion } from './ConditionalQuestions/CoverCropHerbicideQuantityQuestion';
import { CoverCropDestructionMachineQuestion } from './ConditionalQuestions/CoverCropDestructionMachineQuestion';
import { CoverCropDestructionDepthQuestion } from './ConditionalQuestions/CoverCropDestructionDepthQuestion';
import { BooleanRadioGroup } from '@shared/components/form/BooleanRadioGroup/BooleanRadioGroup';
import { CoverCropDestructionWidthQuestion } from './ConditionalQuestions/CoverCropDestructionWidthQuestion';
import { FormRow } from '@shared/components';
import { NumberInput } from '@soil-capital/ui-kit/components';
import { CoverCropFormSkeleton } from './CoverCropFormSkeleton';
import { CoverCropDestructionMethodQuestion } from './ConditionalQuestions/CoverCropDestructionMethodQuestion';
import { CoverCropDestructionHeightQuestion } from './ConditionalQuestions/CoverCropDestructionHeightQuestion';
import { DIVIDER_TEXT_ALIGNMENT, DividerText } from '@shared/components/DividerText/DividerText';
import { CoverCropDestructionDateQuestion } from './ConditionalQuestions/CoverCropDestructionDateQuestion';
import { CoverCropDestructionPassageQuestion } from './ConditionalQuestions/CoverCropDestructionPassageQuestion';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../schema/coverCropFormSchema';

export const CoverCropForm = ({ errorDisplayed }: { errorDisplayed: [string, string] | undefined }): JSX.Element => {
    const {
        onSubmit,
        methods,
        isLoading,
        destructionMethodOptions,
        destructionHeightOptions,
        destructionMachinesOptions,
        seedingMachinesOptions,
        pesticidesOptions,
    } = useCoverCropForm();
    const { classes, cx } = useCoverCropFormStyles();
    const { t } = useTranslation();

    return (
        <>
            {isLoading ? <CoverCropFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form
                    onSubmit={onSubmit}
                    className={cx(classes.form, isLoading && classes.hiddenForm)}
                    style={isLoading ? { visibility: 'hidden', height: 0 } : {}}
                >
                    <FormRow>
                        <FormQuestion
                            question={t('encoding-cover-crop.question.crop-origin')}
                            tooltipTitleKey="encoding-cover-crop.question.tooltips.title.crop-origin"
                            tooltipTextKey="encoding-cover-crop.question.tooltips.text.crop-origin"
                        />
                        <BooleanRadioGroup
                            onChange={() => onSubmit()}
                            name={COVER_CROP_FORM_INPUT_NAMES.IS_FROM_PREVIOUS_CROP}
                        />
                    </FormRow>

                    <CoverCropSpeciesQuestion onChange={onSubmit} />

                    <CoverCropLegumeQuestion onChange={onSubmit} />

                    <CoverCropSeedingMachineQuestion options={seedingMachinesOptions} onChange={onSubmit} />

                    <CoverCropSeedingDepthQuestion onChange={onSubmit} />

                    <CoverCropSeedingWidthQuestion onChange={onSubmit} />

                    <CoverCropInterrowSpacingQuestion onChange={onSubmit} />

                    <CoverCropDestructionDateQuestion
                        onChange={onSubmit}
                        error={errorDisplayed && errorDisplayed[0] === COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE}
                    />

                    <CoverCropDestructionMethodQuestion options={destructionMethodOptions} onChange={onSubmit} />

                    <CoverCropDestructionHeightQuestion options={destructionHeightOptions} onChange={onSubmit} />

                    <CoverCropHerbicideQuestion options={pesticidesOptions} onChange={onSubmit} />

                    <CoverCropHerbicideQuantityQuestion onChange={onSubmit} />

                    <CoverCropDestructionPassageQuestion onChange={onSubmit} />

                    <CoverCropDestructionMachineQuestion options={destructionMachinesOptions} onChange={onSubmit} />

                    <CoverCropDestructionWidthQuestion onChange={onSubmit} />

                    <CoverCropDestructionDepthQuestion onChange={onSubmit} />

                    <DividerText text={t('constants.optional')} textAlign={DIVIDER_TEXT_ALIGNMENT.LEFT} />

                    <FormRow>
                        <FormQuestion
                            question={t('encoding-cover-crop.question.biomass')}
                            tooltipTitleKey="encoding-cover-crop.question.tooltips.title.biomass"
                            tooltipTextKey="encoding-cover-crop.question.tooltips.text.biomass"
                        />
                        <NumberInput
                            min={0}
                            value={methods.watch(COVER_CROP_FORM_INPUT_NAMES.BIOMASS) ?? null}
                            onChange={(value) => {
                                methods.setValue(COVER_CROP_FORM_INPUT_NAMES.BIOMASS, value);
                                onSubmit();
                            }}
                            suffix={t('constants.unit.tons-ha')}
                            style={{ width: '250px' }}
                            placeholder={t('encoding-cover-crop.question.placeholder.tonnes-per-ha')}
                        />
                    </FormRow>
                </form>
            </FormProvider>
        </>
    );
};
