import api from '@shared/api';
import {
    CoverCropsResponseT,
    FarmSeasonMineralProductsResponseT,
    FarmSeasonNitrogenPerCropResponseT,
    CropRotationResponseT,
    FertilisersPerCropResponseT,
    FarmSeasonOrganicFertilizerResponseT,
    TillagePracticeResponseT,
    FarmSeasonOrganicFertilizerPerCropResponseT,
    ResidueManagementResponseT,
    fuelConsumptionResponseT,
    livestockResponseT,
    LegumesResponseT,
    chalkResponseT,
    irrigationResponseT,
} from './graphs.types';

export const graphsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getNitrogenPerCrop: builder.query<FarmSeasonNitrogenPerCropResponseT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/mineral-fertilization/nitrogen-per-crop`,
                method: 'GET',
            }),
        }),
        getMineralProducts: builder.query<FarmSeasonMineralProductsResponseT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/mineral-fertilization/mineral-products`,
                method: 'GET',
            }),
        }),
        getCropRotation: builder.query<CropRotationResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/crop-rotation`,
                method: 'GET',
            }),
        }),
        getOrganicFertiliser: builder.query<FarmSeasonOrganicFertilizerResponseT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/organic-fertilization/organic-fertilizer`,
                method: 'GET',
            }),
        }),
        getMineralFertilizersPerCrop: builder.query<FertilisersPerCropResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/tables/mineral-fertilization/fertilizers-per-crop`,
                method: 'GET',
            }),
        }),
        getTillagePractice: builder.query<TillagePracticeResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/tillage-practices`,
                method: 'GET',
            }),
        }),
        getCoverCrops: builder.query<CoverCropsResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/cover-crops`,
                method: 'GET',
            }),
        }),
        getOrganicFertilizerPerCrop: builder.query<
            FarmSeasonOrganicFertilizerPerCropResponseT,
            { farmSeasonId: number }
        >({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/tables/organic-fertilization/organic-fertilizer-per-crop`,
                method: 'GET',
            }),
        }),
        getResidueManagement: builder.query<ResidueManagementResponseT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/residue-management`,
                method: 'GET',
            }),
        }),
        getFuelConsumption: builder.query<fuelConsumptionResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/fuel-consumption`,
                method: 'GET',
            }),
        }),
        getLegumes: builder.query<LegumesResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/graphs/legumes`,
                method: 'GET',
            }),
        }),
        getLivestockData: builder.query<livestockResponseT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/livestock`,
                method: 'GET',
            }),
        }),
        getChalk: builder.query<chalkResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/chalk`,
                method: 'GET',
            }),
        }),
        getIrrigationData: builder.query<irrigationResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/irrigation`,
                method: 'GET',
            }),
        }),
    }),
});

export const useGetChalkQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetChalkQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetLegumesQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetLegumesQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetLivestockDataQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetLivestockDataQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetFuelConsumptionQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetFuelConsumptionQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetResidueManagementQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetResidueManagementQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetOrganicFertilizerPerCropQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetOrganicFertilizerPerCropQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetCoverCropsQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetCoverCropsQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetTillagePracticeQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetTillagePracticeQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetOrganicFertiliserQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetOrganicFertiliserQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetCropRotationQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetCropRotationQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetNitrogenPerCropQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetNitrogenPerCropQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetMineralProductsQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetMineralProductsQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetMineralFertilizersPerCropQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetMineralFertilizersPerCropQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetIrrigationDataQuery = (params: { farmSeasonId: number | null }) => {
    return graphsApi.useGetIrrigationDataQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};
