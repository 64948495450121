import { Skeleton, Typography } from '@soil-capital/ui-kit/material-core';
import { useResultsCardStyles } from './ResultsCard.style';
import { NoDataFound } from '../GraphContainer/NoDataFound/NoDataFound';

type ResultsCardT = {
    data: {
        title: string;
        subtitle?: string;
    }[];
    isLoading?: boolean;
    className?: string;
    hasNoData?: boolean;
    hasBorders?: boolean;
};

export const ResultsCard = ({ data, isLoading = false, className, hasNoData, hasBorders = false }: ResultsCardT) => {
    const { classes, cx } = useResultsCardStyles({ hasBorders });

    if (isLoading) {
        return (
            <div className={cx(classes.container, className)}>
                <Skeleton variant="text" width="50%" height={45} />
                <Skeleton variant="text" width="80%" height={20} />
            </div>
        );
    }

    return (
        <div className={cx(classes.container, className)}>
            {hasNoData && !isLoading ? (
                <NoDataFound />
            ) : (
                data.map((el, index) => (
                    <div className={classes.elementContainer} key={index}>
                        <Typography variant="h1">{el.title}</Typography>
                        {el.subtitle && <Typography className={classes.subtitle}>{el.subtitle}</Typography>}
                    </div>
                ))
            )}
        </div>
    );
};
