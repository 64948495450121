import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { FieldSelectionPanel } from '@modules/encoding/shared/components/FieldSelectionPanel/FieldSelectionPanel';
import { Button } from '@soil-capital/ui-kit/components';
import { FieldCropDestinationLeftPanel } from '../../components/FieldCropDestinationLeftPanel/FieldCropDestinationLeftPanel';
import { FormProvider } from 'react-hook-form';
import { useCropDestinationPageLogic } from './useCropDestinationPageLogic';
import { useCropDestinationPageFormLogic } from './useCropDestinationPageFormLogic';
import { CropDestinationFilters } from '../../components/CropDestinationFilters/CropDestinationFilters';
import { useState } from 'react';

export const CropDestinationPage = () => {
    const [filteredFieldIds, setFilteredFieldIds] = useState<Set<number> | null>(null);
    const { methods, selectedFieldIds, selectedCropDestinationId } = useCropDestinationPageFormLogic();
    const {
        t,
        onFinishClick,
        handleUpdateSelectedCropDestination,
        fieldIdsToExcludeFromCropDestination,
        isRotationProgressLoading,
        isLoading,
        handleUpdateAllFieldCropDestinations,
        isAllFieldCropUpdateLoading,
        areAllFieldSelected,
    } = useCropDestinationPageLogic(filteredFieldIds, selectedCropDestinationId, selectedFieldIds, methods);

    return (
        <FormProvider {...methods}>
            <form>
                <FullscreenModal
                    open={true}
                    onClose={() => undefined}
                    title={t('encoding-rotation.crop-destination.title')}
                    leftPanel={<FieldCropDestinationLeftPanel />}
                    rightPanel={
                        <FieldSelectionPanel
                            title={t('encoding-rotation.crop-destination.right-panel.title')}
                            description={t('encoding-rotation.crop-destination.right-panel.description')}
                            isLoading={isLoading}
                            onDeselect={(fieldId) => handleUpdateSelectedCropDestination(fieldId, 'remove')}
                            onSelect={(fieldId) => handleUpdateSelectedCropDestination(fieldId, 'add')}
                            excludedFieldIds={fieldIdsToExcludeFromCropDestination}
                            isCropAssignation
                            filteredFieldIds={filteredFieldIds}
                            filters={
                                <CropDestinationFilters
                                    filteredFieldIds={filteredFieldIds}
                                    setFilteredFieldIds={setFilteredFieldIds}
                                    selectedCropDestinationId={selectedCropDestinationId}
                                    isLoading={isLoading}
                                    t={t}
                                    isAllFieldCropUpdateLoading={isAllFieldCropUpdateLoading}
                                    areAllFieldSelected={areAllFieldSelected}
                                    handleUpdateAllFieldCropDestinations={handleUpdateAllFieldCropDestinations}
                                />
                            }
                        />
                    }
                    headerButton={
                        <Button
                            disabled={isAllFieldCropUpdateLoading}
                            onClick={onFinishClick}
                            variant="contained"
                            color="primary"
                            loading={isRotationProgressLoading}
                        >
                            {t('constants.finish')}
                        </Button>
                    }
                />
            </form>
        </FormProvider>
    );
};
