import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { useCoverCropSpeciesQuestion } from './useCoverCropSpeciesQuestion';
import { useFormContext } from 'react-hook-form';
import { NumberInput } from '@soil-capital/ui-kit/components';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';

export const CoverCropSpeciesQuestion = ({ onChange }: { onChange: () => void }) => {
    const { t } = useTranslation();
    const { isVisible } = useCoverCropSpeciesQuestion();
    const methods = useFormContext();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.species-number')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.species-number"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.species-number"
                    />
                    <NumberInput
                        min={0}
                        incrementationStep={1}
                        value={methods.watch(COVER_CROP_FORM_INPUT_NAMES.SPECIES_COUNT) ?? null}
                        onChange={(value) => {
                            methods.setValue(COVER_CROP_FORM_INPUT_NAMES.SPECIES_COUNT, value);
                            onChange();
                        }}
                        placeholder={t('encoding-cover-crop.question.placeholder.species-number')}
                    />
                </FormRow>
            )}
        </>
    );
};
