import { FormRow } from '@shared/components';
import { NumberInput } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

export type SprayingPassageInputPropsT = {
    onChange: () => void;
    name: string;
    question?: string;
    tooltipTitleKey?: string;
    tooltipTextKey?: string;
    errorMessage?: string | null;
    disabled?: boolean;
};

export const SprayingPassageInput = ({
    onChange,
    name,
    question,
    tooltipTitleKey,
    tooltipTextKey,
    errorMessage,
    disabled,
}: SprayingPassageInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();

    if (!context) throw new Error('This component should be used inside a FormProvider');

    return (
        <FormRow>
            <FormQuestion
                question={question ?? t('encoding-technical-itinerary.spraying.passages')}
                tooltipTitleKey={tooltipTitleKey ?? 'encoding-technical-itinerary.spraying.passages-tooltip-title'}
                tooltipTextKey={tooltipTextKey ?? 'encoding-technical-itinerary.spraying.passages-tooltip-text'}
            />
            <NumberInput
                min={0}
                incrementationStep={1}
                value={context.watch(name) ?? null}
                onChange={(value) => {
                    context.setValue(name, value);
                    onChange();
                }}
                error={!!errorMessage}
                helperText={errorMessage}
                data-testid={`spraying-passage-input-${name}`}
                style={{ width: '250px' }}
                disabled={disabled}
            />
        </FormRow>
    );
};
