import { Table } from '@soil-capital/ui-kit/components';
import { ResultsCard } from '../ResultsCard/ResultsCard';
import { useIrrigationGraph } from './useIrrigationGraph.data';
import { GraphContainer } from '../GraphContainer/GraphContainer';
import { useIrrigationGraphStyles } from './IrrigationGraph.style';
import { TableSkeleton } from '../TableSkeleton/TableSkeleton';
import { useTranslation } from 'react-i18next';
import { IconIrrigation } from '@soil-capital/ui-kit/icons';

export const IrrigationGraph = () => {
    const { percentageIrrigatedFormatted, formattedIrrigationPerCrop, isLoading, hasNoData } = useIrrigationGraph();
    const { t } = useTranslation();
    const { classes } = useIrrigationGraphStyles();
    return (
        <GraphContainer
            title={t('results.summary.emissions.irrigation-title')}
            hasNoData={hasNoData}
            orientation={'vertical'}
            titleIcon={<IconIrrigation />}
        >
            <div className={classes.container}>
                <ResultsCard
                    data={percentageIrrigatedFormatted}
                    className={classes.cardContainer}
                    isLoading={isLoading}
                    hasBorders={true}
                />
                {isLoading ? <TableSkeleton /> : <Table {...formattedIrrigationPerCrop} />}
            </div>
        </GraphContainer>
    );
};
