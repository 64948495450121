import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useGetCoverCropsQuery } from '@modules/results/api/graphs.api';
import { stackedBarGraphBuilder } from '@modules/results/utils/stackedBarGraphBuilder';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { coverCropsGraphTheme } from '@modules/results/utils/theme/coverCropsGraphTheme';

export const useCoverCropsGraphData = () => {
    const { t } = useTranslation();
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSeason.selectedSeasonId);
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();
    const coverCropsGraphRef = useRef(null);

    const {
        data: coverCropsData,
        isLoading: isLoadingCoverCropsData,
        isFetching: isFetchingCoverCropsData,
    } = useGetCoverCropsQuery({
        farmSeasonId: selectedSeasonId,
    });

    useEffect(() => {
        if (coverCropsData && coverCropsGraphRef.current) {
            const coverCropsGraph = echarts.init(coverCropsGraphRef.current, coverCropsGraphTheme, { renderer: 'svg' });
            const formattedData: Record<string, Record<string, { value: number; name: string }>> = {
                [t('constants.harvest-year', { harvestYear })]: coverCropsData.reduce((acc, item) => {
                    acc[t(`${item.type_slug}`)] = {
                        value: item.total_area,
                        name: `${t(item.type_slug)} | ${item.total_area} ${t('constants.hectare-unit')}`,
                    };
                    return acc;
                }, {} as Record<string, { value: number; name: string }>),
            };
            const options = stackedBarGraphBuilder({
                data: formattedData,
                yTitle: t('results.summary.sequestration.cover-crops.y-axis-legend'),
                hasLegend: true,
                hasTooltip: true,
                tooltipTitle: t('results.summary.sequestration.cover-crops.title'),
                tooltipUnit: t('constants.hectare-unit'),
                tooltipTotal: coverCropsData[0].total_farm_area,
            });
            options.grid.right = '60%';
            options.legend.right = '0%';
            coverCropsGraph.setOption(options);
            return () => {
                coverCropsGraph.dispose();
            };
        }
    }, [coverCropsData, harvestYear, t]);

    const hasNoData = !!(coverCropsData ? coverCropsData.length <= 1 : true);

    return {
        t,
        coverCropsGraphRef,
        isLoading: isLoadingHarvestYear || isLoadingCoverCropsData || isFetchingCoverCropsData,
        hasNoData,
    };
};
