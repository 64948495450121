import { useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';
import {
    useGetMineralProductsQuery,
    useGetNitrogenPerCropQuery,
    useGetMineralFertilizersPerCropQuery,
} from '@modules/results/api/graphs.api';
import { useTranslation } from 'react-i18next';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { stackedBarGraphBuilder } from '@modules/results/utils/stackedBarGraphBuilder';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';
import { mineralFertilizersGraphTheme } from '@modules/results/utils/theme/mineralFertilizersGraphTheme';
import { baseGraphTheme } from '@modules/results/utils/theme/baseGraphTheme';

export const useMineralFertilizationGraphs = () => {
    const nitrogenGraphRef = useRef(null);
    const mineralProductsGraphRef = useRef(null);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSeason.selectedSeasonId);
    const { t } = useTranslation();
    const {
        data: nitrogenPerCropData,
        isLoading: isLoadingNitrogenPerCrop,
        isFetching: isFetchingNitrogenPerCrop,
    } = useGetNitrogenPerCropQuery({
        farmSeasonId: selectedSeasonId,
    });
    const {
        data: mineralProductsData,
        isLoading: isLoadingMineralProducts,
        isFetching: isFetchingMineralProducts,
    } = useGetMineralProductsQuery({
        farmSeasonId: selectedSeasonId,
    });
    const {
        data: fertilizersPerCropData,
        isLoading: isLoadingFertilizersPerCrop,
        isFetching: isFetchingFertilizersPerCrop,
    } = useGetMineralFertilizersPerCropQuery({
        farmSeasonId: selectedSeasonId,
    });
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();

    const formattedFertilizersPerCropData = useMemo(() => {
        if (fertilizersPerCropData) {
            const headers = [
                t('constants.crop'),
                ...fertilizersPerCropData?.fertilisers.map((el) => {
                    if (el.slug) {
                        return t(`db.fertilisers.${el.slug}`);
                    } else {
                        return el.name;
                    }
                }),
            ];
            const fertiliserIds = fertilizersPerCropData?.fertilisers.map((fert) => fert.id);
            const data = fertilizersPerCropData?.crops.map((crop) => {
                const row: (string | number)[] = [t(`db.crop.slug-${crop.slug}`)];
                fertiliserIds?.forEach((fertId: number) => {
                    const fert = crop.fertilisers.find((cf) => cf.id === fertId);
                    row.push(
                        fert ? `${Number(fert.quantity).toFixed(2)} ${fert.unit}/${t('constants.hectare-unit')}` : 0,
                    );
                });
                return row;
            });
            return {
                headers,
                data,
            };
        } else {
            return {
                headers: [],
                data: [[]],
            };
        }
    }, [fertilizersPerCropData, t]);

    useEffect(() => {
        if (nitrogenPerCropData && nitrogenGraphRef.current) {
            const nitrogenGraph = echarts.init(nitrogenGraphRef.current, baseGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear })]: nitrogenPerCropData.reduce((acc, item) => {
                    acc[t(`db.crop.slug-${item.slug}`)] = parseFloat(Number(item.total_nitrogen).toFixed(2));
                    return acc;
                }, {} as Record<string, number>),
            };
            const defaultOptions = {
                ...stackedBarGraphBuilder({
                    data: formattedData,
                    yTitle: t('results.summary.emissions.nitrogen-consommation-y-axis-legend'),
                    hasLegend: true,
                    hasTooltip: true,
                    tooltipTitle: t('results.summary.emissions.nitrogen-consommation'),
                    tooltipUnit: 'Kg N',
                }),
            };
            defaultOptions.grid.right = '55%';
            defaultOptions.legend.right = '20%';
            nitrogenGraph.setOption({
                ...defaultOptions,
            });
            return () => {
                nitrogenGraph.dispose();
            };
        }
    }, [nitrogenPerCropData, harvestYear, t]);

    useEffect(() => {
        if (mineralProductsData && mineralProductsGraphRef.current) {
            const mineralProductsGraph = echarts.init(mineralProductsGraphRef.current, mineralFertilizersGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear })]: mineralProductsData.reduce((acc, item) => {
                    const translatedName = item.slug ? t(`db.fertilisers.${item.slug}`) : item.name;
                    acc[translatedName] = parseFloat(Number(item.total_quantity).toFixed(2));
                    return acc;
                }, {} as Record<string, number>),
            };
            const defaultOptions = stackedBarGraphBuilder({
                data: formattedData,
                yTitle: t('results.summary.emissions.products-consommation-y-axis-legend'),
                hasLegend: true,
                hasTooltip: true,
                tooltipTitle: t('results.summary.emissions.mineral-products-consommation'),
                tooltipUnit: 'Kg',
            });
            defaultOptions.grid.right = '55%';
            defaultOptions.legend.right = '0%';
            mineralProductsGraph.setOption({
                ...defaultOptions,
            });
            return () => {
                mineralProductsGraph.dispose();
            };
        }
    }, [mineralProductsData, harvestYear, t]);

    const hasNoData = useMemo(
        () =>
            nitrogenPerCropData?.length === 0 &&
            mineralProductsData?.length === 0 &&
            fertilizersPerCropData?.fertilisers?.length === 0 &&
            !isLoadingHarvestYear &&
            !isLoadingNitrogenPerCrop &&
            !isLoadingMineralProducts &&
            !isLoadingFertilizersPerCrop &&
            !isFetchingNitrogenPerCrop &&
            !isFetchingMineralProducts &&
            !isFetchingFertilizersPerCrop,
        [
            fertilizersPerCropData?.fertilisers?.length,
            isFetchingFertilizersPerCrop,
            isFetchingMineralProducts,
            isFetchingNitrogenPerCrop,
            isLoadingFertilizersPerCrop,
            isLoadingHarvestYear,
            isLoadingMineralProducts,
            isLoadingNitrogenPerCrop,
            mineralProductsData?.length,
            nitrogenPerCropData?.length,
        ],
    );

    return {
        hasNoData,
        nitrogenGraphRef,
        mineralProductsGraphRef,
        formattedFertilizersPerCropData,
        isLoading:
            isLoadingHarvestYear ||
            isLoadingNitrogenPerCrop ||
            isLoadingMineralProducts ||
            isLoadingFertilizersPerCrop ||
            isFetchingNitrogenPerCrop ||
            isFetchingMineralProducts ||
            isFetchingFertilizersPerCrop,
    };
};
