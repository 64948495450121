import { useTranslation } from 'react-i18next';
import { GraphContainer } from '../GraphContainer/GraphContainer';
import { ResultsCard } from '../ResultsCard/ResultsCard';
import { useFuelConsumptionGraph } from './useFuelConsumptionGraph.data';
import { useFuelComsumptionGraphStyles } from './FuelComsumptionGraph.style';
import { IconFuel } from '@soil-capital/ui-kit/icons';

export const FuelConsumptionGraph = () => {
    const { t } = useTranslation();
    const { classes } = useFuelComsumptionGraphStyles();
    const { fuelConsumptionData, isLoadingFuelConsumption } = useFuelConsumptionGraph();

    return (
        <GraphContainer
            title={t('results.summary.emissions.fuel-consumption-title')}
            hasBorders
            className={classes.container}
            titleIcon={<IconFuel />}
        >
            <ResultsCard
                data={[
                    {
                        title: `${fuelConsumptionData?.total_energy.toFixed(1)} ${t('constants.unit.liter-ha')}`,
                        subtitle: t('results.summary.emissions.fuel-consumption-subtitle'),
                    },
                ]}
                isLoading={isLoadingFuelConsumption}
                className={classes.cardContainer}
                hasBorders
            />
        </GraphContainer>
    );
};
