import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useMineralFertiliserFormStyles } from './useMineralFertiliserForm.style';
import { useMineralFertiliserForm } from './useMineralFertiliserForm.logic';
import { MineralFertiliserFormSkeleton } from './MineralFertiliserFormSkeleton';
import { Card } from '@soil-capital/ui-kit/components';
import { MineralFertiliserProductsInput } from '../MineralFertiliserProductsInput/MineralFertiliserProductsInput';
import { MineralFertiliserSprayerWidthInput } from '../MineralFertiliserSprayerWidthInput/MineralFertiliserSprayerWidthInput';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { useReuseOperation } from '../../../operationDuplication/reuse/hooks/useReuseOperation';
import { ReuseOperationSelect } from '../../../operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { MINERAL_FERTILISER_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';

export const MineralFertiliserForm = () => {
    const { t } = useTranslation();
    const { classes, cx } = useMineralFertiliserFormStyles();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const { methods, isFormLoading, onSubmit, getFormValues, refetchFormData } = useMineralFertiliserForm();
    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        onOperationReused: refetchFormData,
        getFormValues,
    });

    return (
        <>
            {isFormLoading ? <MineralFertiliserFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form onSubmit={onSubmit} className={cx(classes.form, isFormLoading && classes.hiddenForm)}>
                    <ReuseOperationSelect
                        fieldCropId={fieldCropId}
                        farmSeasonId={seasonId}
                        operationId={operationId}
                        onReuseOperationTriggered={handleReuseOperation}
                        disabled={isReuseOperationLoading}
                        actionLoading={isReuseOperationLoading}
                    />

                    <Card title={t('constants.solid')}>
                        <MineralFertiliserProductsInput
                            physicalState="solid"
                            onChange={onSubmit}
                            name={MINERAL_FERTILISER_FORM_INPUT_NAMES.MINERAL_FERTILISER_SOLID_PRODUCTS}
                            disabled={isReuseOperationLoading}
                        />
                    </Card>
                    <Card title={t('constants.liquid')}>
                        <MineralFertiliserProductsInput
                            physicalState="liquid"
                            onChange={onSubmit}
                            name={MINERAL_FERTILISER_FORM_INPUT_NAMES.MINERAL_FERTILISER_LIQUID_PRODUCTS}
                            disabled={isReuseOperationLoading}
                        />
                        <MineralFertiliserSprayerWidthInput onChange={onSubmit} disabled={isReuseOperationLoading} />
                    </Card>
                </form>
            </FormProvider>
        </>
    );
};
