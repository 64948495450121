import { Button, Card, Quote } from '@soil-capital/ui-kit/components';
import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { IconArrowLeft } from '@soil-capital/ui-kit/icons';
import { useNavigate } from 'react-router-dom';
import { resultRoutesConfig } from '@modules/results/result.routes';
import { CarbonResultsGraph } from '@modules/results/components/CarbonResultsGraph/CarbonResultsGraph';
import { useResultsEarningStyle } from '@modules/results/pages/ResultsEarning/ResultsEarning.style';
import { IconInfo } from '@soil-capital/ui-kit/icons';
import { Accordion } from '@soil-capital/ui-kit';
import { ResultsCalculationDetail } from '@modules/results/components/ResultsCalculationDetail/ResultsCalculationDetail';
import { useResultsEarning } from '@modules/results/pages/ResultsEarning/useResultsEarning';
import { truncateDecimals } from '@shared/utils/truncateDecimals';

export const ResultsEarning = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { classes } = useResultsEarningStyle();
    const { currentSeasonId, results, harvestYear, isNetEmitter, isAuditDone, isLoading } = useResultsEarning();

    const handleBackClicked = () => navigate(`/${currentSeasonId}/results/${resultRoutesConfig.SUMMARY}`);

    if (isLoading || !harvestYear || !results || isNetEmitter === null) return null;

    return (
        <PageWithHeader
            header={
                <Button onClick={handleBackClicked} size="large" startIcon={<IconArrowLeft />} variant="text">
                    {t('constants.back')}
                </Button>
            }
            content={
                <>
                    <div className={classes.header}>
                        <Typography variant="h1">{t('results.earning.title')}</Typography>
                        {!isAuditDone && (
                            <Card className={classes.disclaimerContainer}>
                                <IconInfo color="success" />
                                <Typography variant="body2">{t('results.earning.disclaimer')}</Typography>
                            </Card>
                        )}
                    </div>
                    <Quote
                        author={{
                            avatar: '/assets/images/agro-max-avatar.jpeg',
                            name: 'Max,',
                            role: t('results.introduction.quote.author.role'),
                        }}
                    >
                        <Typography sx={{ mb: 1 }} variant="h3">
                            {isNetEmitter
                                ? t('results.earning.quote.emission-title')
                                : t('results.earning.quote.sequestration-title')}
                        </Typography>
                        {t('results.earning.quote.content')}
                    </Quote>
                    <div className={classes.resultsContainer}>
                        <div className={classes.graphContainer}>
                            <CarbonResultsGraph
                                value={results.ghg_balance_average}
                                year={harvestYear}
                                yearLegend={t('constants.baseline')}
                                tooltip={t(
                                    isNetEmitter
                                        ? 'results.earning.graph.tooltip.emission'
                                        : 'results.earning.graph.tooltip.sequestration',
                                    {
                                        tonsPerHa: truncateDecimals(Math.abs(results.ghg_balance_average), 2),
                                        tonsTotal: truncateDecimals(Math.abs(results.ghg_balance), 2),
                                    },
                                )}
                            />
                        </div>
                        <div className={classes.earningContainer}>
                            <Typography variant="caps" className={classes.earningHeader}>
                                {t('results.earning.carbon-footprint.calculation-detail.title')}
                            </Typography>
                            <div className={classes.detailedCalculation}>
                                <ResultsCalculationDetail
                                    ghgBalance={results.ghg_balance}
                                    cropEmissions={results.crop_net_emissions}
                                    cropSequestrations={results.crop_net_sequestrations}
                                    livestockEmissions={results.livestock_emissions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.accordionsContainer}>
                        <Accordion
                            Header={
                                <Accordion.Header className={classes.accordionHeader}>
                                    {t('results.earning.carbon-footprint.how-calculation-work.title')}
                                </Accordion.Header>
                            }
                            Content={
                                <Accordion.Content className={classes.accordionContent}>
                                    {t('results.earning.carbon-footprint.how-calculation-work.description')}
                                </Accordion.Content>
                            }
                            className={classes.accordion}
                        />
                    </div>
                </>
            }
        />
    );
};
