import { MenuItem, Select } from '@soil-capital/ui-kit';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useSelectSeasonsStyles } from './SelectSeasons.style';
import { useSelectSeasonsLogic } from '@modules/encoding/modules/layout/components/SelectSeasons/useSelectSeasonsLogic';

type SelectSeasonsProps = {
    className?: string;
};

export const SELECT_SEASONS_DATA_TEST_ID = 'select-seasons';

export const SelectSeasons = ({ className }: SelectSeasonsProps) => {
    const { t } = useTranslation();
    const { classes, cx } = useSelectSeasonsStyles();
    const { isLoading, handleSeasonChanged, options, selectedSeason } = useSelectSeasonsLogic();

    if (isLoading || !selectedSeason?.id) return <Skeleton variant="rounded" width={150} height={40} />;

    return (
        <Select
            data-testid={SELECT_SEASONS_DATA_TEST_ID}
            name="season"
            className={cx(classes.select, className)}
            placeholder={t('constants.select')}
            onChange={handleSeasonChanged}
            value={selectedSeason?.id.toString()}
            renderValue={() => {
                const isYearHistoric =
                    selectedSeason?.carbon_programme_year === -1 || selectedSeason?.carbon_programme_year === -2;
                return `${isYearHistoric ? t('constants.season-historic') : t('db.operation_type.harvest.harvest')} ${
                    selectedSeason?.harvest_year
                }`;
            }}
        >
            <MenuItem sx={{ display: 'none' }} value="" disabled>
                {t('constants.select')}
            </MenuItem>
            {options?.map((season) => {
                const isYearClosedString = Boolean(season.summary_validated_at)
                    ? t('constants.season-closed')
                    : t('constants.season-open');
                const isYearHistoric = season.carbon_programme_year === -1 || season.carbon_programme_year === -2;
                return (
                    <MenuItem key={season.id} value={season.id.toString()}>
                        {`${isYearHistoric ? t('constants.season-historic') : t('db.operation_type.harvest.harvest')} ${
                            season.harvest_year
                        } (${isYearClosedString})`}
                    </MenuItem>
                );
            })}
        </Select>
    );
};
