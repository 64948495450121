import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { resultRoutesConfig } from '@modules/results/result.routes';
import { setSelectedSeasonId } from '@modules/results/store/resultsSeasonSelectionSlice';
import entities from '@shared/entities';
import { SharedStateT } from '@shared/store';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useResultsSummaryLogic = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { seasonState } = entities.season.useState({ farmId: currentSeason?.farm_id });
    const handleBackClicked = () => navigate(`/${currentSeason?.id}/results/${resultRoutesConfig.SUMMARY}`);

    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSeason.selectedSeasonId);

    const harvestOptions = seasonState.list.filter((season) => season);

    const handleHarvestChange = (event: SelectChangeEvent<unknown>) => {
        const selectedId = event.target.value ? parseInt(event.target.value as string, 10) : null;
        dispatch(setSelectedSeasonId(selectedId));
    };

    useEffect(() => {
        if (selectedSeasonId === null) {
            dispatch(setSelectedSeasonId(currentSeasonId));
        }
    }, [selectedSeasonId, currentSeasonId, dispatch]);

    return {
        isLoading: seasonState.isLoading || currentSeasonLoading,
        handleBackClicked,
        selectedSeasonId,
        harvestOptions,
        handleHarvestChange,
    };
};
