import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { ImagePanel } from '@modules/encoding/shared/components/ImagePanel/ImagePanel';
import { useIrrigationFieldSelectionModalLogic } from './useIrrigationFieldSelectionModal.logic';
import { FieldSelectionPanel } from '@modules/encoding/shared/components/FieldSelectionPanel/FieldSelectionPanel';
import { ConfirmDeleteIrrigationModal } from '../ConfirmDeleteIrrigationModal/ConfirmDeleteIrrigationModal';
import { FieldSelectionFilters } from '@modules/encoding/shared/components/FieldSelectionFilters/FieldSelectionFilters';
import { useState } from 'react';

export type IrrigationFieldSelectionModalProps = {
    open: boolean;
    onClose: () => void;
};

export const IrrigationFieldSelectionModal = ({ open, onClose }: IrrigationFieldSelectionModalProps) => {
    const { t } = useTranslation();
    const [filteredFieldIds, setFilteredFieldIds] = useState<Set<number> | null>(null);

    const {
        handleCloseIrrigationFieldSelectionModal,
        isCloseLoading,
        harvestYear,
        isIrrigationLoading,
        handleDeleteIrrigation,
        handleCreateIrrigation,
        confirmDeleteIrrigationController,
        excludedFieldIds,
    } = useIrrigationFieldSelectionModalLogic({
        onClose,
    });

    return (
        <>
            <FullscreenModal
                open={open}
                onClose={() => undefined}
                title={t('encoding-irrigation.modal.title')}
                leftPanel={<ImagePanel src={'/assets/images/drainage_irrigation.svg'} />}
                rightPanel={
                    <FieldSelectionPanel
                        title={t('encoding-irrigation.modal.right-panel.title')}
                        description={t('encoding-irrigation.modal.right-panel.description', { harvestYear })}
                        isLoading={isIrrigationLoading}
                        onDeselect={handleDeleteIrrigation}
                        onSelect={handleCreateIrrigation}
                        excludedFieldIds={excludedFieldIds}
                        filteredFieldIds={filteredFieldIds}
                        filters={
                            <FieldSelectionFilters
                                onFilteredIdsChange={setFilteredFieldIds}
                                isLoading={isIrrigationLoading}
                                withToggle
                            />
                        }
                    />
                }
                headerButton={
                    <Button
                        onClick={handleCloseIrrigationFieldSelectionModal}
                        variant="contained"
                        color="primary"
                        loading={isCloseLoading}
                    >
                        {t('constants.finish')}
                    </Button>
                }
            />
            <ConfirmDeleteIrrigationModal modalController={confirmDeleteIrrigationController} />
        </>
    );
};
