import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useCoverCropDestructionDepthQuestion } from './useCoverCropDestructionDepthQuestion';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@soil-capital/ui-kit/components';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';

export const CoverCropDestructionDepthQuestion = ({ onChange }: { onChange: () => void }) => {
    const { isVisible } = useCoverCropDestructionDepthQuestion();
    const methods = useFormContext();
    const { t } = useTranslation();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.destruction-depth')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.destruction-depth"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.destruction-depth"
                    />
                    <NumberInput
                        min={0}
                        value={methods.watch(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DEPTH) ?? null}
                        onChange={(value) => {
                            methods.setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DEPTH, value);
                            onChange();
                        }}
                        suffix={t('constants.unit.cm')}
                        placeholder={t('encoding-cover-crop.question.placeholder.destruction-depth')}
                        style={{ width: '250px' }}
                    />
                </FormRow>
            )}
        </>
    );
};
