import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import entities from '@shared/entities';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';

export const useSelectSeasonsLogic = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentFarm } = useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const options = seasonState.list.sort((a, b) =>
        a.harvest_year && b.harvest_year ? a.harvest_year - b.harvest_year : 0,
    );

    const handleSeasonChanged = (v: SelectChangeEvent<unknown>) => {
        const path = pathname.replace(`${currentSeasonId}`, `${v.target.value}`);
        navigate(path);
    };

    const isLoading = seasonState.isLoading;
    const selectedSeason = options.length ? seasonState.getById(currentSeasonId) : null;

    return {
        options,
        handleSeasonChanged,
        isLoading,
        selectedSeason,
    };
};
