import { NumberInput } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { MINERAL_FERTILISER_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';
import { useMineralFertiliserSprayerWidthInputVisibility } from './useMineralFertiliserSprayerWidthInputVisibility';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type DestructionSprayerWidthInputPropsT = {
    onChange: () => void;
    disabled?: boolean;
};

export const MineralFertiliserSprayerWidthInput = ({ onChange, disabled }: DestructionSprayerWidthInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplayMineralFertiliserSprayerWidthInput } = useMineralFertiliserSprayerWidthInputVisibility();

    if (!context) throw new Error('MineralFertiliserSprayerWidthInput should be used inside a FormProvider');

    return shouldDisplayMineralFertiliserSprayerWidthInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.mineral-fertiliser.question.sprayer-width')}
                tooltipTitleKey="encoding-technical-itinerary.mineral-fertiliser.question.sprayer-width.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.mineral-fertiliser.question.sprayer-width.tooltip.text"
            />
            <NumberInput
                data-testid="mineral-fertiliser-sprayer-width-input"
                suffix={t('constants.unit.meters')}
                min={0}
                onChange={(value) => {
                    context.setValue(MINERAL_FERTILISER_FORM_INPUT_NAMES.SPRAYER_WIDTH, value);
                    onChange();
                }}
                value={context.watch(MINERAL_FERTILISER_FORM_INPUT_NAMES.SPRAYER_WIDTH) ?? null}
                style={{ width: '250px' }}
                disabled={disabled}
            />
        </FormRow>
    ) : (
        <></>
    );
};
