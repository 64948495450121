import { useGetCropDestinationsQuery } from '@modules/encoding/modules/rotation/api/cropDestinationApi';
import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import { z } from 'zod';

export const FIELD_CROP_DESTINATION = {
    SELECTED_CROP_DESTINATION_ID: 'selected_crop_destination_id',
    SELECTED_FIELD_IDS: 'selected_field_ids',
} as const;

export const useCropDestinationPageFormLogic = () => {
    const { data: cropDestinations } = useGetCropDestinationsQuery();

    const schema = z.object({
        [FIELD_CROP_DESTINATION.SELECTED_CROP_DESTINATION_ID]: z.number().nullable(),
        [FIELD_CROP_DESTINATION.SELECTED_FIELD_IDS]: z.array(z.number()),
    });

    const defaultValues = {
        [FIELD_CROP_DESTINATION.SELECTED_CROP_DESTINATION_ID]: null,
        [FIELD_CROP_DESTINATION.SELECTED_FIELD_IDS]: [],
    };
    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: true,
    });

    const selectedFieldIds = methods.watch(FIELD_CROP_DESTINATION.SELECTED_FIELD_IDS);
    const selectedCropDestinationId = methods.watch(FIELD_CROP_DESTINATION.SELECTED_CROP_DESTINATION_ID);

    useEffect(() => {
        if (!methods.getValues(FIELD_CROP_DESTINATION.SELECTED_CROP_DESTINATION_ID)) {
            methods.setValue('selected_crop_destination_id', cropDestinations?.[0].id ?? null);
        }
    }, [cropDestinations, methods]);

    return {
        methods,
        selectedFieldIds,
        selectedCropDestinationId,
    };
};
