import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { PROGRESS_DETAIL_CATEGORIES } from '@shared/entities/progress/progress.types';
import { formatNumber } from '@shared/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type FieldDataT = {
    id: number;
    name: string;
    image: string;
    infoText: string;
    maxProgress: number;
    currentProgress: number;
};

export const useIntroductionContentData = () => {
    const { t } = useTranslation();

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();

    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { irrigationState } = entities.irrigation.useState({ farmSeasonId: currentSeasonId });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticSeasonState } = entities.staticSeason.useState();

    const { getFieldThumbnail } = encodingShared.useGetFieldThumbnail({ seasonId: currentSeasonId });

    const isDataLoading =
        harvestedFieldsLoading ||
        currentFarmLoading ||
        currentSeasonLoading ||
        fieldState.isLoading ||
        cropState.isLoading ||
        fieldCropState.isLoading ||
        staticCropState.isLoading ||
        irrigationState.isLoading ||
        progressState.isLoading ||
        staticSeasonState.isLoading;

    const getFieldInfoText = ({ fieldId, fieldArea }: { fieldId: number; fieldArea: number }) => {
        const firstFieldCrop = fieldCropState.getByFieldId({ fieldId }).at(0);
        const crop = cropState.getById(firstFieldCrop?.farm_season_crop_id);
        const staticCrop = staticCropState.getById(crop?.crop_id);

        const infoText = `${t(staticCrop?.translation_slug ?? '')} ${formatNumber(fieldArea, 2)} HA`;

        return infoText;
    };

    const irrigatedFields = harvestedFields.filter((field) => !!irrigationState.getByFieldId(field.id));
    const fieldDataList = irrigatedFields.map<FieldDataT>((field) => {
        const infoText = getFieldInfoText({ fieldArea: field.area, fieldId: field.id });
        const fieldThumbnail = getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at });
        const fieldIrrigation = irrigationState.getByFieldId(field.id);
        const irrigationProgress =
            Object.entries(fieldIrrigation || {}).filter(
                ([key, value]) => key !== 'id' && key !== 'farm_season_field_id' && value !== null,
            )?.length || 0;
        const irrigationMaxProgress = Object.keys(fieldIrrigation || {}).filter(
            (key) => key !== 'id' && key !== 'farm_season_field_id',
        )?.length;

        return {
            currentProgress: irrigationProgress,
            id: field.id,
            image: fieldThumbnail,
            infoText,
            maxProgress: irrigationMaxProgress,
            name: field.name,
        };
    });

    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;

    // Assignation progress
    const maxIrrigationAssignationProgress = 1; // We know there is only 1 field definition step
    const progressDetails = progressState.getBySlug('irrigation')?.progress_details;
    const fieldAssignationProgress = progressDetails?.find((pd) => pd.category === 'irrigation-field-assignation');
    const currentIrrigationAssignationProgress = fieldAssignationProgress?.is_done ? 1 : 0;

    // Form (Practices) Progress
    const irrigationFormProgress = useMemo(() => {
        return progressState.computeProgressDetail(
            'irrigation',
            'category',
            PROGRESS_DETAIL_CATEGORIES.IRRIGATION_FORM,
        );
    }, [progressState]);
    const maxIrrigationPracticeProgress = irrigationFormProgress.total;
    const currentIrrigationPracticeProgress = irrigationFormProgress.done;

    return {
        isDataLoading,
        fieldDataList,
        harvestYear,
        maxIrrigationPracticeProgress,
        currentIrrigationPracticeProgress,
        maxIrrigationAssignationProgress,
        currentIrrigationAssignationProgress,
    };
};
