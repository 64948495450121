import { makeStyles } from '@soil-capital/ui-kit/style';

export const useLivestockGraphStyles = makeStyles()((theme) => ({
    container: {
        height: '100%',
        width: '50%',
        overflow: 'hidden',
        justifyContent: 'flex-start',
    },
    cardContainer: {
        height: '100%',
        justifyContent: 'flex-start',
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
}));
