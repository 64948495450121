import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useTableSkeletonStyles } from './TableSkeleton.style';

export const TableSkeleton = () => {
    const { classes, cx } = useTableSkeletonStyles();

    return (
        <div className={classes.skeletonContainer}>
            <div className={classes.skeletonHeaderContainer}>
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
            </div>
            <div className={cx(classes.skeletonRowContainer, classes.skeletonRowWithBackground)}>
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
            </div>
            <div className={classes.skeletonRowContainer}>
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
            </div>
            <div className={cx(classes.skeletonRowContainer, classes.skeletonRowWithBackground)}>
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
                <Skeleton variant="text" width="20%" height={30} />
            </div>
        </div>
    );
};
