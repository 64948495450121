import authApi from '@modules/auth/api/authApi';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useIsEncodingDoneGuard } from '@shared/guards/IsEncodingDoneGuard';

export const useCanSeeResults = () => {
    const { data, isLoading: isLoadingPermissions } = authApi.useGetPermissionsQuery();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { isLoading: isLoadingBaseline, isYoyFarmSeason } = useBaselineFarmSeason();
    const isEncodingDone = useIsEncodingDoneGuard();

    const isLoading = isLoadingPermissions || currentSeasonLoading || isLoadingBaseline;

    const canSeeResults =
        data?.permissions?.includes('FP_CAN_SEE_ALL_RESULT') ||
        data?.permissions?.includes('FP_CAN_SEE_ASSIGNED-ASSOCIATE_RESULT') ||
        data?.permissions?.includes('FP_CAN_SEE_ASSIGNED-PARTNER_RESULT') ||
        data?.permissions?.includes('FP_CAN_SEE_SELF_RESULT');

    const canSeeUnpublishedResults = data?.permissions?.includes('FP_CAN_SEE_UNPUBLISHED_RESULT');

    // Results were computed - does not mean they are published
    const hasResults = currentSeason?.has_results;

    // Results were published
    const areResultsValidated = !!currentSeason?.has_results_published;

    // Two possible scenarios:
    // 1. Results are computed and published -> All user with permission to see results can access them
    // 2. Results are computed but not published -> Only user with permission to see unpublished results can access them
    const hasAccessToResults = canSeeResults && hasResults && (areResultsValidated || canSeeUnpublishedResults);

    return {
        canSeeResults: (canSeeResults && isEncodingDone) || isYoyFarmSeason,
        canSeeUnpublishedResults,
        hasResults,
        areResultsValidated,
        hasAccessToResults,
        isLoading,
    };
};
