import { useTranslation } from 'react-i18next';
import { Header } from '@shared/components/Header/Header';
import { Typography } from '@soil-capital/ui-kit/material-core';
import GlobalSaveStatus from '@modules/encoding/modules/layout/components/GlobalSaveStatus/GlobalSaveStatus';
import { IconFarm } from '@soil-capital/ui-kit/icons';
import { useEncodingHeaderStyles } from '@modules/encoding/modules/layout/components/EncodingHeader/EncodingHeader.style';
import { Button } from '@soil-capital/ui-kit';
import { useNavigate } from 'react-router';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';

export default () => {
    const { t } = useTranslation();
    const { classes } = useEncodingHeaderStyles();
    const navigate = useNavigate();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { activeSeason, isLoading: isLoadingActiveSeason } = useGetActiveSeason(currentSeason?.farm_id);

    const handleBack = () => navigate(`/${activeSeason?.id}`);

    return (
        <Header
            leftElement={<Typography variant="h3">{t('components.encoding-header.title')}</Typography>}
            rightElement={
                <div className={classes.rightElement}>
                    <GlobalSaveStatus />
                    <Button
                        disabled={currentSeasonLoading || isLoadingActiveSeason}
                        variant="text"
                        className={classes.backButton}
                        onClick={handleBack}
                    >
                        <IconFarm className={classes.icon} />
                        <Typography variant="caption">{t('layout.encoding-nav.go-back-home')}</Typography>
                    </Button>
                </div>
            }
        />
    );
};
