import { SearchInput, Switch } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useFieldSelectionFiltersStyle } from './useFieldSelectionFiltersStyle';
import { SkeletonContainer } from '@shared/components/SkeletonContainer/SkeletonContainer';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useFieldSelectionFiltersLogic } from './useFieldSelectionFiltersLogic';

export const FieldSelectionFilters = ({
    onFilteredIdsChange,
    isLoading,
    isDuplication,
    withToggle,
}: {
    onFilteredIdsChange: (filteredFieldIds: Set<number> | null) => void;
    isLoading: boolean;
    isDuplication?: boolean;
    withToggle?: boolean;
}) => {
    const { t } = useTranslation();
    const { classes } = useFieldSelectionFiltersStyle();

    const { handleSearchChange, handleSwitchChange, searchValue, toggleSelectedFields } = useFieldSelectionFiltersLogic(
        { onFilteredIdsChange, isDuplication },
    );

    return (
        <SkeletonContainer
            isLoading={isLoading}
            className={classes.header}
            Content={
                <>
                    <SearchInput
                        placeholder={t('constants.search-field')}
                        value={searchValue}
                        onClearClick={() => handleSearchChange('')}
                        onChange={(e) => handleSearchChange(e.target.value)}
                    />
                    {withToggle && (
                        <div className={classes.switchContainer}>
                            <Switch
                                labelPlacement="start"
                                checked={toggleSelectedFields}
                                onChange={() => handleSwitchChange(!toggleSelectedFields)}
                                label={t('constants.fields-selected')}
                            />
                        </div>
                    )}
                </>
            }
            Skeletons={
                <>
                    <Skeleton variant="rounded" sx={{ height: '100%' }} />
                </>
            }
        />
    );
};
