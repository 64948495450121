import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities, { FieldT } from '@shared/entities';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetStaticCrops } from '@shared/hooks/useGetStaticCrops';
import { SharedStateT } from '@shared/store';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetFilters,
    CROP_DESTINATION_FIELDS_FILTERS,
    FilterType,
    changeActiveFilter,
} from '../../store/filter.slice';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useCropDestinationStep } from '../../hooks/useCropDestinationStep';

export const useCropDestinationPageFiltersLogic = (
    filteredFieldIds: Set<number> | null,
    setFilteredFieldIds: Dispatch<SetStateAction<Set<number> | null>>,
    selectedCropDestinationId: number | null,
) => {
    const dispatch = useDispatch();
    const search = useSelector((state: SharedStateT) => state.rotationFilters.search);
    const { fieldIdsWithSelectedCropDestinationOrNeedingAssignation, fieldIdsToExcludeFromCropDestination } =
        useCropDestinationStep(selectedCropDestinationId);

    const selectedFilter = useSelector((state: SharedStateT) => state.rotationFilters.selectedFilter);
    const selectedFarmSeasonFieldId = useSelector((state: SharedStateT) => state.rotationFilters.farmSeasonFieldId);
    const selectedFieldIds = useSelector((state: SharedStateT) => state.fieldSelectionAssignation.selectedIds);

    const { staticCropState } = useGetStaticCrops();
    const { fieldState } = useGetFarmSeasonFields();
    const { cropState } = useGetFarmSeasonCrops();
    const { currentSeasonId } = useCurrentSeasonId();
    const { getCropsAssignedToAField } = cropState.useGetCropsAssignedToAField();
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });

    const farmSeasonFields = fieldState.list.filter(
        (field) => !fieldIdsToExcludeFromCropDestination.includes(field.id),
    );
    const cropWithDestination = staticCropState.list.filter((crop) => crop.crop_destination_static_data_ids.length > 0);
    const cropWithDestinationId = cropWithDestination.map((crop) => crop.id);
    const farmSeasonCropWithDestination = cropState.list.filter((crop) => cropWithDestinationId.includes(crop.crop_id));
    const cropIdsWithSelectedDestination = fieldCropState.list
        .filter(
            (fieldCrop) =>
                fieldCrop.crop_destination_static_data_id === null ||
                fieldCrop.crop_destination_static_data_id === selectedCropDestinationId,
        )
        .map((fieldCrop) => fieldCrop.farm_season_crop_id);
    const cropIdsToAddToCurrentFilter = farmSeasonCropWithDestination
        .filter((crop) => cropIdsWithSelectedDestination.includes(crop.id))
        .map((crop) => crop.crop_id);
    const cropFilters = cropWithDestination
        .filter((crop) => cropIdsToAddToCurrentFilter.includes(crop.id))
        .map((crop) => ({
            value: crop.id.toString(),
            translationKey: `db.crop.slug-${crop.slug}`,
        }));

    const doesFieldMatchSearch = useCallback(
        (field: FieldT) => field.name.toLowerCase().includes(search.toLowerCase()) || !search,
        [search],
    );
    const doesFieldHaveCrop = useCallback(
        (field: FieldT) => getCropsAssignedToAField(field.id).length > 0,
        [getCropsAssignedToAField],
    );
    const fieldWithoutCropDestination = useCallback(
        (field: FieldT) =>
            fieldCropState
                .getByFieldId({ fieldId: field.id })
                .find((fieldCrop) => fieldCrop.crop_destination_static_data_id === null),
        [fieldCropState],
    );
    const isFieldSelected = useCallback((field: FieldT) => !!selectedFieldIds.includes(field.id), [selectedFieldIds]);
    const doesFieldHaveSpecificCrop = useCallback(
        (field: FieldT, cropId: number) => getCropsAssignedToAField(field.id).some((crop) => crop.crop_id === cropId),
        [getCropsAssignedToAField],
    );

    const fieldIdsMatchingFilters = useCallback(
        (fields: FieldT[], filter: FilterType): Set<number> => {
            const matchingFieldIds = new Set<number>();

            fields.forEach((field) => {
                if (!doesFieldMatchSearch(field)) return;
                if (selectedFarmSeasonFieldId && field.id !== selectedFarmSeasonFieldId) return;
                switch (filter) {
                    case CROP_DESTINATION_FIELDS_FILTERS.FIELDS_WITH_CROP:
                        if (doesFieldHaveCrop(field)) matchingFieldIds.add(field.id);
                        break;
                    case CROP_DESTINATION_FIELDS_FILTERS.FIELDS_WITHOUT_DESTINATION:
                        if (fieldWithoutCropDestination(field)) matchingFieldIds.add(field.id);
                        break;
                    case CROP_DESTINATION_FIELDS_FILTERS.SELECTED_FIELDS:
                        if (isFieldSelected(field)) matchingFieldIds.add(field.id);
                        break;
                    case CROP_DESTINATION_FIELDS_FILTERS.NO_FILTER:
                        if (fieldIdsWithSelectedCropDestinationOrNeedingAssignation.includes(field.id))
                            matchingFieldIds.add(field.id);
                        break;
                    default:
                        const cropId = filter.split('_').pop();
                        if (cropId && doesFieldHaveSpecificCrop(field, parseInt(cropId))) {
                            matchingFieldIds.add(field.id);
                        }
                        break;
                }
            });
            return matchingFieldIds;
        },
        [
            doesFieldMatchSearch,
            selectedFarmSeasonFieldId,
            doesFieldHaveCrop,
            fieldWithoutCropDestination,
            isFieldSelected,
            doesFieldHaveSpecificCrop,
            fieldIdsWithSelectedCropDestinationOrNeedingAssignation,
        ],
    );

    const handleFilterChanged = (value: FilterType) => {
        dispatch(changeActiveFilter(value));
        setFilteredFieldIds(fieldIdsMatchingFilters(farmSeasonFields, value));
    };
    const handleFilterReset = () => {
        dispatch(resetFilters());
        setFilteredFieldIds(fieldIdsMatchingFilters(farmSeasonFields, 'NO_FILTER'));
    };

    // reset filter when crop destination changes to have the correct crops
    useEffect(() => {
        handleFilterReset();
        setFilteredFieldIds(fieldIdsMatchingFilters(farmSeasonFields, 'NO_FILTER'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCropDestinationId]);

    return {
        selectedFilter,
        handleFilterChanged,
        handleFilterReset,
        cropFilters,
        filteredFieldIds,
        setFilteredFieldIds,
    };
};
