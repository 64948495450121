import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { useSeedingMachineryDepthInputVisibility } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingMachineryDepthInput/useSeedingMachineryDepthInputVisibility';

type SeedingMachineryDepthInputProps = {
    onChange: () => void;
};

export const SeedingMachineryDepthInput = ({ onChange }: SeedingMachineryDepthInputProps) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplay } = useSeedingMachineryDepthInputVisibility();

    if (!context) throw new Error('SeedingMachineryDepthInput must be used within a FormProvider');

    return shouldDisplay ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.seeding.question.seed-machinery-depth')}
                tooltipTitleKey="encoding-technical-itinerary.seeding.question.seed-machinery-depth.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.seeding.question.seed-machinery-depth.tooltip.text"
            />
            <NumberInput
                min={0}
                value={context.watch(SEEDING_FORM_INPUT_NAMES.MACHINERY_DEPTH) ?? null}
                onChange={(value) => {
                    context.setValue(SEEDING_FORM_INPUT_NAMES.MACHINERY_DEPTH, value);
                    onChange();
                }}
                sx={{ maxWidth: 250 }}
                suffix="cm"
            />
        </FormRow>
    ) : (
        <></>
    );
};
