import { FieldSelectionFilters } from '@modules/encoding/shared/components/FieldSelectionFilters/FieldSelectionFilters';
import { Checkbox } from '@soil-capital/ui-kit/components';
import { Skeleton, useTheme } from '@soil-capital/ui-kit/material-core';
import { IconCheckCircle, IconClose, IconSeed } from '@soil-capital/ui-kit/icons';
import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { CROP_DESTINATION_FIELDS_FILTERS } from '../../store/filter.slice';
import { FieldListFilters } from '../FieldCropAssignationModal/components/FieldListFilters/FieldListFilters';
import { useCropDestinationPageFiltersLogic } from './useCropDestinationFilters.logic';
import { Dispatch, SetStateAction } from 'react';

type CropDestinationFiltersProps = {
    filteredFieldIds: Set<number> | null;
    setFilteredFieldIds: Dispatch<SetStateAction<Set<number> | null>>;
    selectedCropDestinationId: number | null;
    isLoading: boolean;
    t: (key: string) => string;
    isAllFieldCropUpdateLoading: boolean;
    areAllFieldSelected: boolean;
    handleUpdateAllFieldCropDestinations: (action: 'add' | 'remove') => void;
};

export const CropDestinationFilters = ({
    filteredFieldIds,
    setFilteredFieldIds,
    selectedCropDestinationId,
    isLoading,
    t,
    isAllFieldCropUpdateLoading,
    areAllFieldSelected,
    handleUpdateAllFieldCropDestinations,
}: CropDestinationFiltersProps) => {
    const theme = useTheme();
    const { selectedFilter, handleFilterChanged, handleFilterReset, cropFilters } = useCropDestinationPageFiltersLogic(
        filteredFieldIds,
        setFilteredFieldIds,
        selectedCropDestinationId,
    );
    return (
        <Grid paddingBottom={theme.spacing(1)} gap={theme.spacing(1)} display="flex" flexDirection="column">
            <FieldSelectionFilters onFilteredIdsChange={setFilteredFieldIds} isLoading={isLoading} />
            <FieldListFilters
                isLoading={isLoading}
                filters={[
                    {
                        value: CROP_DESTINATION_FIELDS_FILTERS.SELECTED_FIELDS,
                        icon: IconCheckCircle,
                        translationKey: 'constants.fields-selected',
                    },
                    {
                        value: CROP_DESTINATION_FIELDS_FILTERS.FIELDS_WITHOUT_DESTINATION,
                        icon: IconClose,
                        translationKey: 'constants.fields-without-destination',
                    },
                    {
                        value: CROP_DESTINATION_FIELDS_FILTERS.FIELDS_WITH_CROP,
                        icon: IconSeed,
                        translationKey: 'constants.fields-with-crop',
                        disabled: true,
                    },
                    ...cropFilters,
                ]}
                value={selectedFilter}
                onChange={handleFilterChanged}
                onReset={handleFilterReset}
            />
            {isLoading ? (
                <Grid display="flex" gap={theme.spacing(1)}>
                    <Skeleton variant="rectangular" height={theme.spacing(3)} width={theme.spacing(3)} />
                    <Skeleton variant="text" width="40%" />
                </Grid>
            ) : (
                <Checkbox
                    label={
                        <Typography
                            variant="caption"
                            color={theme.palette.darkScale[600]}
                            paddingLeft={theme.spacing(1)}
                        >
                            {t('constants.select-all')}
                        </Typography>
                    }
                    name="select-all"
                    disabled={isAllFieldCropUpdateLoading}
                    checked={areAllFieldSelected}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleUpdateAllFieldCropDestinations(e.target.checked ? 'add' : 'remove');
                    }}
                />
            )}
        </Grid>
    );
};
