import { makeStyles } from '@soil-capital/ui-kit/style';

export const useTillagePracticeGraphStyles = makeStyles()(() => ({
    container: {
        width: '50%',
    },
    graphContainer: {
        height: '412px',
        width: '100%',
    },
}));
