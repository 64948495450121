import { IconOrganicFertilisation } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../GraphContainer/GraphContainer';
import { useOrganicFertilizationGraphsStyles } from './OrganicFertilizationGraphs.style';
import { useOrganicFertilizationGraphsData } from './useOrganicFertilizationGraphs.data';
import { Table } from '@soil-capital/ui-kit/components';
import { TableSkeleton } from '../TableSkeleton/TableSkeleton';

export const OrganicFertilizationGraphs = () => {
    const { productConsumptionGraphRef, t, fertilizerSlugs, formattedTableData, isLoading, hasNoData } =
        useOrganicFertilizationGraphsData();
    const { classes } = useOrganicFertilizationGraphsStyles();

    return (
        <GraphContainer
            title={t('results.summary.sequestration.organic-fertilization-title')}
            titleIcon={<IconOrganicFertilisation />}
            orientation="vertical"
            hasNoData={hasNoData}
        >
            <div className={classes.flexbox}>
                <GraphContainer
                    title={t('results.summary.sequestration.organic-fertilization.product-consumption')}
                    isLoading={isLoading}
                    hasBorders
                >
                    <div ref={productConsumptionGraphRef} className={classes.graphContainer} />
                </GraphContainer>

                {isLoading ? (
                    <div className={classes.skeletonContainer}>
                        <TableSkeleton />
                    </div>
                ) : (
                    <div className={classes.tableContainer}>
                        <Table headers={fertilizerSlugs} data={formattedTableData} />
                    </div>
                )}
            </div>
        </GraphContainer>
    );
};
