import { EmissionsSummary } from '@modules/results/components/EmissionsSummary/EmissionsSummary';
import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { Button, Select } from '@soil-capital/ui-kit/components';
import { IconArrowLeft } from '@soil-capital/ui-kit/icons';
import { useResultsSummaryStyle } from './ResultsSummary.style';
import { CropRotationGraph } from '@modules/results/components/CropRotationGraph/CropRotationGraph';
import { MenuItem, Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { SequestrationSummary } from '@modules/results/components/SequestrationSummary/SequestrationSummary';
import { useResultsSummaryLogic } from './useResultsSummary.logic';
import { SummarySkeleton } from '@modules/results/components/SummarySkeleton/SummarySkeleton';

export const ResultsSummary = () => {
    const { t } = useTranslation();
    const { classes } = useResultsSummaryStyle();
    const { selectedSeasonId, harvestOptions, handleHarvestChange, handleBackClicked, isLoading } =
        useResultsSummaryLogic();

    if (isLoading) {
        return <SummarySkeleton />;
    }

    return (
        <PageWithHeader
            header={
                <div className={classes.flexBox}>
                    <Button onClick={handleBackClicked} size="large" startIcon={<IconArrowLeft />} variant="text">
                        {t('constants.back')}
                    </Button>
                    <Select value={selectedSeasonId} onChange={handleHarvestChange} sx={{ width: '240px' }}>
                        {harvestOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {`${t(
                                    option?.carbon_programme_year ?? 0 < 0
                                        ? 'constants.historic-harvest-year'
                                        : 'constants.harvest-year',
                                    { harvestYear: option.harvest_year },
                                )}`}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            }
            content={
                <>
                    <div className={classes.title}>
                        <Typography variant="h1">{t('results.summary.title')}</Typography>
                    </div>
                    <CropRotationGraph />
                    <div className={classes.textContainer}>
                        <Typography variant="h2">{t('results.summary.description-title')}</Typography>
                        <Typography variant="body2">{t('results.summary.description-subtitle')}</Typography>
                    </div>
                    <SequestrationSummary />
                    <EmissionsSummary />
                </>
            }
            contentClasses={classes.content}
        />
    );
};
