import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { fieldSelectionAssignationSlice } from '@modules/encoding/shared/store/fieldSelectionSlice';
import entities from '@shared/entities';
import { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FIELD_CROP_DESTINATION } from './useCropDestinationPageFormLogic';
import { useCropDestinationStep } from '../../hooks/useCropDestinationStep';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { UseFormReturn } from 'react-hook-form';
import { SharedStateT } from '@shared/store';

export const useCropDestinationPageLogic = (
    filteredFieldIds: Set<number> | null,
    selectedCropDestinationId: number | null,
    selectedFieldIds: number[],
    methods: UseFormReturn<
        {
            selected_crop_destination_id: number | null;
            selected_field_ids: number[];
        },
        undefined
    >,
) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentSeasonId } = useCurrentSeasonId();
    const { readOnly } = useFarmSeasonReadOnly();

    const selectedFieldIdsSlice = useSelector((state: SharedStateT) => state.fieldSelectionAssignation.selectedIds);
    const [recomputeRotationProgress, { isLoading: isRotationProgressLoading }] =
        entities.progress.useComputeRotationProgress();
    const [updateFieldCrop] = entities.fieldCrop.useUpdate();
    const [updateFieldCrops, { isLoading: isAllFieldCropUpdateLoading }] = entities.fieldCrop.useUpdateMany();
    const { fieldIdsToExcludeFromCropDestination, fieldCropsWithCropAssignationNeed, isCropDestinationStepLoading } =
        useCropDestinationStep(selectedCropDestinationId);
    const redirectToRotation = () => navigate(`/${currentSeasonId}/rotation`);
    const areAllFieldSelected =
        filteredFieldIds instanceof Set &&
        selectedFieldIdsSlice.length > 0 &&
        Array.from(filteredFieldIds).every((id) => selectedFieldIdsSlice.includes(id));

    const withFieldLoadingState = useCallback(
        async (fieldId: number, asyncFn: () => Promise<void>) => {
            dispatch(fieldSelectionAssignationSlice.actions.addLoadingFieldId(fieldId));
            dispatch(fieldSelectionAssignationSlice.actions.addDisabledFieldId(fieldId));
            try {
                await asyncFn();
            } finally {
                dispatch(fieldSelectionAssignationSlice.actions.removeLoadingFieldId(fieldId));
                dispatch(fieldSelectionAssignationSlice.actions.removeDisabledFieldId(fieldId));
            }
        },
        [dispatch],
    );

    const withFieldsLoadingState = useCallback(
        async (fieldIds: number[], asyncFn: () => Promise<void>) => {
            dispatch(fieldSelectionAssignationSlice.actions.setLoadingFieldIds(fieldIds));
            dispatch(fieldSelectionAssignationSlice.actions.setDisabledFieldIds(fieldIds));
            try {
                await asyncFn();
            } finally {
                dispatch(fieldSelectionAssignationSlice.actions.setLoadingFieldIds([]));
                dispatch(fieldSelectionAssignationSlice.actions.setDisabledFieldIds([]));
            }
        },
        [dispatch],
    );

    const hasDataChange = useRef(false);

    const handleUpdateAllFieldCropDestination = useCallback(
        (fieldCropIds: number[], fieldIds: number[], cropDestinationStaticDataId: number | null) => {
            return withFieldsLoadingState(fieldIds, async () => {
                hasDataChange.current = true;
                await updateFieldCrops({
                    farmSeasonId: currentSeasonId,
                    body: fieldCropIds.map((fieldCropId) => ({
                        id: fieldCropId,
                        crop_destination_static_data_id: cropDestinationStaticDataId,
                    })),
                });
            });
        },
        [currentSeasonId, withFieldsLoadingState, updateFieldCrops],
    );

    const handleUpdateAllFieldCropDestinations = (operationType: 'remove' | 'add') => {
        const updatedFieldsId = filteredFieldIds
            ? [...filteredFieldIds]
            : fieldCropsWithCropAssignationNeed
                  .filter(
                      (fieldCrop) =>
                          fieldCrop.crop_destination_static_data_id === null ||
                          fieldCrop.crop_destination_static_data_id === selectedCropDestinationId,
                  )
                  .map((fieldCrop) => fieldCrop.farm_season_field_id);
        methods.setValue(FIELD_CROP_DESTINATION.SELECTED_FIELD_IDS, operationType === 'add' ? updatedFieldsId : []);

        const selectedFieldCrops = fieldCropsWithCropAssignationNeed.filter((fieldCrop) =>
            updatedFieldsId.includes(fieldCrop.farm_season_field_id),
        );
        handleUpdateAllFieldCropDestination(
            selectedFieldCrops.map((fieldCrop) => fieldCrop.id),
            selectedFieldCrops.map((fieldCrop) => fieldCrop.farm_season_field_id),
            operationType === 'add' ? selectedCropDestinationId : null,
        );

        dispatch(
            fieldSelectionAssignationSlice.actions.setSelectedFieldIds(operationType === 'add' ? updatedFieldsId : []),
        );
    };

    const handleUpdateFieldCropDestination = useCallback(
        (fieldCropId: number, fieldId: number, cropDestinationStaticDataId: number | null) => {
            return withFieldLoadingState(fieldId, async () => {
                hasDataChange.current = true;
                await updateFieldCrop({
                    farmSeasonId: currentSeasonId,
                    id: fieldCropId,
                    body: {
                        crop_destination_static_data_id: cropDestinationStaticDataId,
                    },
                });
            });
        },
        [currentSeasonId, updateFieldCrop, withFieldLoadingState],
    );

    const handleUpdateSelectedCropDestination = (fieldId: number, operationType: 'remove' | 'add') => {
        const updatedFieldsId = [...selectedFieldIds, fieldId];
        methods.setValue(FIELD_CROP_DESTINATION.SELECTED_FIELD_IDS, updatedFieldsId);

        const selectedFieldCrops = fieldCropsWithCropAssignationNeed.filter((fieldCrop) =>
            updatedFieldsId.includes(fieldCrop.farm_season_field_id),
        );

        const selectedFieldCrop = selectedFieldCrops.find((fieldCrop) => fieldCrop.farm_season_field_id === fieldId);
        if (selectedFieldCrop) {
            handleUpdateFieldCropDestination(
                selectedFieldCrop.id,
                selectedFieldCrop.farm_season_field_id,
                operationType === 'add' ? selectedCropDestinationId : null,
            );
        }

        dispatch(fieldSelectionAssignationSlice.actions.setSelectedFieldIds([...selectedFieldIds, fieldId]));
    };

    const onFinishClick = async () => {
        if (!readOnly) {
            await recomputeRotationProgress({ seasonId: currentSeasonId });
        }
        redirectToRotation();
    };

    useEffect(() => {
        const fieldCropWithSelectedCropDestination = fieldCropsWithCropAssignationNeed.filter(
            (fieldCrop) => fieldCrop.crop_destination_static_data_id === selectedCropDestinationId,
        );
        const fieldWithSelectedCropDestinationIds = fieldCropWithSelectedCropDestination.map(
            (fieldCrop) => fieldCrop.farm_season_field_id,
        );
        if (selectedCropDestinationId) {
            dispatch(fieldSelectionAssignationSlice.actions.setSelectedFieldIds(fieldWithSelectedCropDestinationIds));
        }

        if (readOnly) {
            const disabledFieldIds = fieldCropsWithCropAssignationNeed.map(
                (fieldCrop) => fieldCrop.farm_season_field_id,
            );
            dispatch(fieldSelectionAssignationSlice.actions.setDisabledFieldIds(disabledFieldIds));
        }
    }, [
        dispatch,
        fieldCropsWithCropAssignationNeed,
        methods,
        selectedCropDestinationId,
        readOnly,
        isAllFieldCropUpdateLoading,
    ]);

    useEffect(() => {
        dispatch(fieldSelectionAssignationSlice.actions.reset());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        t,
        onFinishClick,
        methods,
        handleUpdateSelectedCropDestination,
        handleUpdateFieldCropDestination,
        fieldIdsToExcludeFromCropDestination,
        isRotationProgressLoading,
        isLoading: isCropDestinationStepLoading || !selectedCropDestinationId,
        selectedCropDestinationId,
        isAllFieldCropUpdateLoading,
        handleUpdateAllFieldCropDestinations,
        areAllFieldSelected,
    };
};
