import { usePageWithHeaderStyle } from '@shared/components/PageWithHeader/PageWithHeader.style';
import { ReactNode } from 'react';

type PageWithHeaderProps = {
    header: ReactNode;
    content: ReactNode;
    headerClasses?: string;
    contentClasses?: string;
};

export const PageWithHeader = ({ header, content, headerClasses, contentClasses }: PageWithHeaderProps) => {
    const { classes, cx } = usePageWithHeaderStyle();

    return (
        <main className={classes.main}>
            <header className={cx(classes.header, headerClasses)}>{header}</header>
            <div className={cx(classes.content, contentClasses)}>{content}</div>
        </main>
    );
};
