import { makeStyles } from 'tss-react/mui';

export const useNoDataFoundStyles = makeStyles<{ orientation: 'vertical' | 'horizontal' }>()(
    (theme, { orientation }) => ({
        image: {
            height: orientation === 'vertical' ? '120px' : '72px',
            width: orientation === 'vertical' ? '120px' : '72px',
            filter: 'grayscale(100%)',
        },
        container: {
            display: 'flex',
            flexDirection: orientation === 'vertical' ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(3),
            width: '100%',
            height: '100%',
        },
    }),
);
