import { useGetFuelConsumptionQuery } from '@modules/results/api/graphs.api';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';

export const useFuelConsumptionGraph = () => {
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSeason.selectedSeasonId);

    const {
        data: fuelConsumptionData,
        isLoading: isLoadingFuelConsumption,
        isFetching: isFetchingFuelConsumption,
    } = useGetFuelConsumptionQuery({
        farmSeasonId: selectedSeasonId,
    });

    return {
        fuelConsumptionData,
        isLoadingFuelConsumption: isLoadingFuelConsumption || isFetchingFuelConsumption,
    };
};
