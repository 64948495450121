import { makeStyles } from '@soil-capital/ui-kit/style';

export const useStackBarGraphSkeletonStyles = makeStyles()((theme) => ({
    loadingContainerHorizontal: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        display: 'flex',
        justifyContent: 'center',
        transform: 'translate(-50%, -50%)',
        alignItems: 'center',
        width: '65%',
        height: '65%',
        gap: theme.spacing(1),
    },
    loadingContainerVertical: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        gap: theme.spacing(1),
    },
    loadingBars: {
        width: '100%',
        paddingLeft: theme.spacing(0),
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'flex-end',
        gap: theme.spacing(1),
    },
    skeleton: {
        transform: 'none',
        borderRadius: theme.shape.borderRadiusS,
    },
    horizontalBar: {
        transform: `translate(-${theme.spacing(0.9)}, 0%)`,
    },
}));
