import { HelpBox, Stack } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { HISTORY_PROGRESS_ENUM } from '@modules/encoding/modules/history/api/historyApi.types';
import { useHistoryContentPageLogic } from '@modules/encoding/modules/history/pages/HistoryContentPage/useHistoryContentPageLogic';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';

export const HistoryContentPage = () => {
    const { t } = useTranslation();

    const {
        isLoading,
        handleContinueClicked,
        maxRotationProgress,
        currentRotationProgress,
        maxTechnicalItineraryProgress,
        currentTechnicalItineraryProgress,
        historyFarmSeasons,
        handleHistoryFarmSeasonRotationRedirect,
        isProgressStepCompleted,
        handleHistoryFarmSeasonTechnicalItineraryRedirect,
    } = useHistoryContentPageLogic();

    return (
        <ContentPage
            Title={<ContentPage.Title>{t('encoding-history.introduction.title')}</ContentPage.Title>}
            Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
            Action={<ContentPage.Action onClick={handleContinueClicked}>{t('constants.continue')}</ContentPage.Action>}
            isLoading={isLoading}
        >
            <IntroProgressLayout
                title={t('encoding-history.introduction.progress-title')}
                leftSection={
                    <Stack spacing={1} mt={1}>
                        <IntroProgressItem
                            openDefault={isLoading ? false : maxRotationProgress !== currentRotationProgress}
                            text={t('encoding-history.introduction.rotation-step-progress')}
                            progress={{
                                max: maxRotationProgress,
                                current: currentRotationProgress,
                            }}
                        >
                            <Stack spacing={1} mt={1}>
                                {historyFarmSeasons?.map((farmSeason) => (
                                    <IntroProgressItem
                                        key={`${farmSeason.id}-rotation`}
                                        onClick={() => handleHistoryFarmSeasonRotationRedirect(farmSeason.id)}
                                        text={`${t('db.operation_type.harvest.harvest')} ${farmSeason.harvest_year}`}
                                        progress={{
                                            max: farmSeason.progress.rotation.total,
                                            current: farmSeason.progress.rotation.done,
                                        }}
                                    />
                                ))}
                            </Stack>
                        </IntroProgressItem>
                        <IntroProgressItem
                            openDefault={
                                isLoading ? false : maxTechnicalItineraryProgress !== currentTechnicalItineraryProgress
                            }
                            text={t('encoding-history.introduction.exception-step-progress')}
                            progress={{
                                max: maxTechnicalItineraryProgress,
                                current: currentTechnicalItineraryProgress,
                            }}
                        >
                            <Stack spacing={1} mt={1}>
                                {historyFarmSeasons?.map((farmSeason) => {
                                    const disabled = !isProgressStepCompleted(
                                        farmSeason,
                                        HISTORY_PROGRESS_ENUM.ROTATION,
                                    );

                                    return (
                                        <IntroProgressItem
                                            hoverText={
                                                disabled
                                                    ? t('encoding-history.introduction.complete-rotation-warning')
                                                    : undefined
                                            }
                                            disabled={disabled}
                                            key={`${farmSeason.id}-technical`}
                                            onClick={() =>
                                                handleHistoryFarmSeasonTechnicalItineraryRedirect(farmSeason.id)
                                            }
                                            text={`${t('db.operation_type.harvest.harvest')} ${
                                                farmSeason.harvest_year
                                            }`}
                                            progress={{
                                                max: farmSeason.progress.technicalItinerary.total,
                                                current: farmSeason.progress.technicalItinerary.done,
                                            }}
                                        />
                                    );
                                })}
                            </Stack>
                        </IntroProgressItem>
                    </Stack>
                }
                rightSection={
                    <HelpBox>
                        <Typography variant="h3">{t('encoding-history.introduction.help-title')}</Typography>
                        <Typography variant="body" component="h4" mt={2}>
                            {t('encoding-history.introduction.help-subtitle-1')}
                        </Typography>
                        <Typography variant="subcaption" color="neutral">
                            {t('encoding-history.introduction.help-description-1')}
                        </Typography>
                        <Typography variant="body" component="h4" mt={2}>
                            {t('encoding-history.introduction.help-subtitle-2')}
                        </Typography>
                        <Typography variant="subcaption" color="neutral">
                            {t('encoding-history.introduction.help-description-2')}
                        </Typography>
                    </HelpBox>
                }
            />
        </ContentPage>
    );
};
