import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { usePieChartGraphSkeletonStyles } from './PieChartGraphSkeleton.style';

export const PieChartGraphSkeleton = () => {
    const { classes } = usePieChartGraphSkeletonStyles();
    return (
        <div className={classes.flexbox}>
            <div className={classes.graphContainer}>
                <div className={classes.donut}>
                    <div className={classes.donutDefault}></div>
                    <div className={classes.donutLine}></div>
                    <div className={classes.donutHole}></div>
                    <div className={classes.donutCase}></div>
                </div>
            </div>
            <div className={classes.legendContainer}>
                <div className={classes.wrapper}>
                    <Skeleton variant="rounded" width="20px" height="20px" />
                    <Skeleton variant="text" width="100px" />
                </div>
                <div className={classes.wrapper}>
                    <Skeleton variant="rounded" width="20px" height="20px" />
                    <Skeleton variant="text" width="80px" />
                </div>
                <div className={classes.wrapper}>
                    <Skeleton variant="rounded" width="20px" height="20px" />
                    <Skeleton variant="text" width="100px" />
                </div>
            </div>
        </div>
    );
};
