import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCropRotationGraphStyles = makeStyles()((theme) => ({
    container: { width: '100%', height: '400px' },
    padding: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(6),
    },
}));
