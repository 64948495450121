import { useOperationPloughingForm } from '@modules/encoding/modules/technicalItinerary/components/OperationPloughingForm/useOperationPloughingForm';
import OperationPloughingFormSkeleton from '@modules/encoding/modules/technicalItinerary/components/OperationPloughingForm/OperationPloughingFormSkeleton';
import { ConditionalTooltip, NumberInput } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useOperationPloughingFormStyle } from '@modules/encoding/modules/technicalItinerary/components/OperationPloughingForm/useOperationPloughingForm.style';
import { FormRow } from '@shared/components';
import { ReuseOperationSelect } from '../../module/operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { useReuseOperation } from '../../module/operationDuplication/reuse/hooks/useReuseOperation';
import { PLOUGHING_FORM_INPUT_NAMES } from '../../schema/ploughingFormSchema';

const OperationPloughingForm = () => {
    const { t } = useTranslation();
    const { classes } = useOperationPloughingFormStyle();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const { watch, setValue, onSubmit, isFormLoading, getFormValues, refetchFormData } = useOperationPloughingForm();
    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        onOperationReused: refetchFormData,
        getFormValues,
    });

    if (isFormLoading) return <OperationPloughingFormSkeleton />;

    return (
        <form onSubmit={onSubmit} className={classes.form}>
            <ReuseOperationSelect
                fieldCropId={fieldCropId}
                farmSeasonId={seasonId}
                operationId={operationId}
                onReuseOperationTriggered={handleReuseOperation}
                disabled={isReuseOperationLoading}
                actionLoading={isReuseOperationLoading}
            />

            <FormRow>
                <div className={classes.label}>
                    <Typography variant="body" color="darkScale.900">
                        {t('encoding-technical-itinerary.ploughing.passage')}
                    </Typography>

                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.ploughing.passage-tooltip-title')}
                        text={t('encoding-technical-itinerary.ploughing.passage-tooltip-text')}
                        scheme="dark"
                        display="flex"
                    />
                </div>
                <NumberInput
                    min={0}
                    incrementationStep={1}
                    value={watch(PLOUGHING_FORM_INPUT_NAMES.PASSAGE) ?? null}
                    onChange={(value) => {
                        setValue(PLOUGHING_FORM_INPUT_NAMES.PASSAGE, value);
                        onSubmit();
                    }}
                    data-testid="passage-input"
                    disabled={isReuseOperationLoading}
                />
            </FormRow>

            <FormRow>
                <div className={classes.label}>
                    <Typography variant="body" color="darkScale.900">
                        {t('encoding-technical-itinerary.ploughing.depth')}
                    </Typography>

                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.ploughing.depth-tooltip-title')}
                        text={t('encoding-technical-itinerary.ploughing.depth-tooltip-text')}
                        scheme="dark"
                        display="flex"
                    />
                </div>
                <NumberInput
                    min={0}
                    value={watch(PLOUGHING_FORM_INPUT_NAMES.DEPTH) ?? null}
                    onChange={(value) => {
                        setValue(PLOUGHING_FORM_INPUT_NAMES.DEPTH, value);
                        onSubmit();
                    }}
                    data-testid="depth-input"
                    suffix={t('constants.unit.cm')}
                    disabled={isReuseOperationLoading}
                />
            </FormRow>
        </form>
    );
};

export default OperationPloughingForm;
