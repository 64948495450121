import { IconMineralFertilisation } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';
import { GraphContainer } from '../GraphContainer/GraphContainer';
import { useMineralFertilizationGraphsStyles } from './MineralFertilizationGraphs.style';
import { useMineralFertilizationGraphs } from './useMineralFertilizationGraphs.data';
import { TableSkeleton } from '../TableSkeleton/TableSkeleton';
import { Table } from '@soil-capital/ui-kit/components';

export const MineralFertilizationGraphs = ({}) => {
    const { t } = useTranslation();
    const { classes } = useMineralFertilizationGraphsStyles();
    const { nitrogenGraphRef, mineralProductsGraphRef, isLoading, formattedFertilizersPerCropData, hasNoData } =
        useMineralFertilizationGraphs();

    return (
        <GraphContainer
            title={t('results.summary.emissions.mineral-fertilization-title')}
            titleIcon={<IconMineralFertilisation />}
            orientation="vertical"
            hasNoData={hasNoData}
            hasBorders
        >
            <div className={classes.verticalContainer}>
                <div className={classes.horizontalContainer}>
                    <GraphContainer
                        title={t('results.summary.emissions.nitrogen-consommation')}
                        isLoading={isLoading}
                        hasBorders
                    >
                        <div ref={nitrogenGraphRef} className={classes.graphContainer} />
                    </GraphContainer>
                    <GraphContainer
                        title={t('results.summary.emissions.mineral-products-consommation')}
                        hasBorders
                        isLoading={isLoading}
                    >
                        <div ref={mineralProductsGraphRef} className={classes.graphContainer} />
                    </GraphContainer>
                </div>
                <div className={classes.tableContainer}>
                    {isLoading ? <TableSkeleton /> : <Table {...formattedFertilizersPerCropData} />}
                </div>
            </div>
        </GraphContainer>
    );
};
