import { useTranslation } from 'react-i18next';
import { GraphContainer } from '../GraphContainer/GraphContainer';
import { ResultsCard } from '../ResultsCard/ResultsCard';
import { useLivestockGraph } from './useLivestockGraph.data';
import { useLivestockGraphStyles } from './LivestockGraph.style';
import { IconCow } from '@soil-capital/ui-kit/icons';

export const LivestockGraph = () => {
    const { t } = useTranslation();

    const { livestock, isLoadingLivestock, hasNoData } = useLivestockGraph();
    const { classes } = useLivestockGraphStyles();

    return (
        <GraphContainer
            hasBorders
            title={t('results.summary.emissions.livestock-title')}
            className={classes.container}
            titleIcon={<IconCow />}
        >
            <ResultsCard
                data={livestock}
                isLoading={isLoadingLivestock}
                className={classes.cardContainer}
                hasNoData={hasNoData}
                hasBorders={!hasNoData}
            />
        </GraphContainer>
    );
};
