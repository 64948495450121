import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ROTATION_FIELDS_FILTERS = {
    NO_FILTER: 'NO_FILTER',
    FIELDS_WITHOUT_CROP: 'FIELDS_WITHOUT_CROP',
    FIELDS_WITH_CROP: 'FIELDS_WITH_CROP',
    FIELDS_SELECTED: 'FIELDS_SELECTED',
} as const;

export const CROP_DESTINATION_FIELDS_FILTERS = {
    FIELDS_WITHOUT_DESTINATION: 'FIELDS_WITHOUT_DESTINATION',
    SELECTED_FIELDS: 'SELECTED_FIELDS',
    FIELDS_WITH_CROP: 'FIELDS_WITH_CROP',
    NO_FILTER: 'NO_FILTER',
} as const;

export type FilterType =
    | (typeof ROTATION_FIELDS_FILTERS)[keyof typeof ROTATION_FIELDS_FILTERS]
    | (typeof CROP_DESTINATION_FIELDS_FILTERS)[keyof typeof CROP_DESTINATION_FIELDS_FILTERS];

const initialState: { selectedFilter: FilterType; search: string; farmSeasonFieldId: number | null } = {
    selectedFilter: ROTATION_FIELDS_FILTERS.NO_FILTER,
    search: '',
    farmSeasonFieldId: null,
};

export const filterSlice = createSlice({
    name: 'rotationFilters',
    initialState,
    reducers: {
        changeSearch: (state, action: PayloadAction<string>) => {
            state.search = action.payload;
        },
        resetSearch: (state) => {
            state.search = '';
        },
        changeActiveFilter: (state, action: PayloadAction<FilterType>) => {
            state.selectedFilter = action.payload;
        },
        changeFieldId: (state, action: PayloadAction<number>) => {
            state.farmSeasonFieldId = action.payload;
        },
        resetFieldId: (state) => {
            state.farmSeasonFieldId = null;
        },
        resetFilters: (state) => {
            state.selectedFilter = ROTATION_FIELDS_FILTERS.NO_FILTER;
            state.search = '';
            state.farmSeasonFieldId = null;
        },
    },
});

export const { changeSearch, resetSearch, changeActiveFilter, resetFilters, changeFieldId, resetFieldId } =
    filterSlice.actions;
