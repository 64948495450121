import { Navigate, useRoutes } from 'react-router-dom';
import { NotFoundPanel } from '@shared/errors/pages/NotFoundPanel';
import { resultRoutesConfig } from '@modules/results/result.routes';
import FadeTransition from '@modules/encoding/shared/components/FadeTransition/FadeTransition';
import { ResultsEarning } from '@modules/results/pages/ResultsEarning/ResultsEarning';
import { Guard } from '@shared/guards/Guard';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { MainScreenLayout } from '@shared/components';
import { Header } from '@shared/components/Header/Header';
import { GlobalNav } from '@shared/components/GlobalNav/GlobalNav';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { ResultsIntroduction } from './pages/ResultsIntroduction/ResultsIntroduction';
import { CanSeeResultsIntroductionGuardAsync } from '@shared/guards/CanSeeResultsSummaryGuardAsync';
import { ResultsSummary } from './pages/ResultsSummary/ResultsSummary';

export default () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const theme = useTheme();

    const routes = useRoutes([
        {
            path: `${resultRoutesConfig.INTRODUCTION}/*`,
            element: (
                <Guard
                    element={<ResultsIntroduction />}
                    guards={[CanSeeResultsIntroductionGuardAsync]}
                    redirectTo={`/${currentSeasonId}`}
                />
            ),
        },
        {
            path: `${resultRoutesConfig.EARNING}/*`,
            element: (
                <Guard
                    element={<ResultsEarning />}
                    guards={[CanSeeResultsIntroductionGuardAsync]}
                    redirectTo={`/${currentSeasonId}/results/${resultRoutesConfig.EARNING}`}
                />
            ),
        },
        {
            path: `${resultRoutesConfig.SUMMARY}/*`,
            element: (
                <Guard
                    element={<ResultsSummary />}
                    guards={[CanSeeResultsIntroductionGuardAsync]}
                    redirectTo={`/${currentSeasonId}/results/${resultRoutesConfig.SUMMARY}`}
                />
            ),
        },
        {
            path: '/',
            element: (
                <Guard
                    element={<Navigate to={resultRoutesConfig.INTRODUCTION} replace />}
                    guards={[CanSeeResultsIntroductionGuardAsync]}
                />
            ),
        },
        {
            path: '*',
            element: <NotFoundPanel />,
        },
    ]);

    return (
        <MainScreenLayout
            headerComponent={<Header />}
            navComponent={<GlobalNav />}
            contentBgColor={theme.palette.darkScale[50]}
        >
            <FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
                {routes}
            </FadeTransition>
        </MainScreenLayout>
    );
};
