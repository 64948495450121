import { makeStyles } from '@soil-capital/ui-kit/style';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
`;

export const usePieChartGraphSkeletonStyles = makeStyles()((theme) => ({
    donut: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        aspectRatio: '1',
    },
    donutCase: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        background: theme.palette.darkScale[300],
        animation: `${pulse} 3s ease-in-out infinite`,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundClip: 'border-box',
        overflow: 'hidden',
    },
    donutDefault: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
    },
    donutLine: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    donutHole: {
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        width: '60%',
        height: '60%',
        background: '#fff',
        position: 'absolute',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3,
    },
    flexbox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    graphContainer: {
        padding: theme.spacing(10),
        height: '100%',
        position: 'relative',
        aspectRatio: '1',
    },
    legendContainer: {
        transform: 'translate(-25%, 0%)',
        paddingRight: theme.spacing(4),
    },
    wrapper: { display: 'flex', alignItems: 'center', gap: theme.spacing(1) },
}));
