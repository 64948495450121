import { useGetCropRotationQuery } from '@modules/results/api/graphs.api';
import { pieChartGraphBuilder } from '@modules/results/utils/pieChartGraphBuilder';
import { SharedStateT } from '@shared/store';
import * as echarts from 'echarts';
import { useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { baseGraphTheme } from '@modules/results/utils/theme/baseGraphTheme';

export const useCropRotationGraphData = () => {
    const { t } = useTranslation();
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSeason.selectedSeasonId);
    const {
        data: cropRotationGraphData,
        isLoading,
        isFetching,
    } = useGetCropRotationQuery({
        farmSeasonId: selectedSeasonId,
    });

    const legendFormatterFunction = useCallback((name: string) => {
        const parts = name.split(',');
        return `${parts[0]} {bold|${parts[1]}} {grey|${parts[2]}}`;
    }, []);

    const cropRotationGraphRef = useRef(null);

    useEffect(() => {
        if (cropRotationGraphData && cropRotationGraphRef.current) {
            const cropRotationGraph = echarts.init(cropRotationGraphRef.current, baseGraphTheme, {
                renderer: 'svg',
            });
            const cropRotationGraphDataWithTranslation = cropRotationGraphData?.crops.map((crop) => ({
                name: `${t(crop?.slug)}, ${crop?.value}%, | ${crop?.total_area} ${t('constants.hectare-unit')}`,
                label: t(crop?.slug),
                ...crop,
            }));
            cropRotationGraph.setOption({
                ...pieChartGraphBuilder({
                    data: cropRotationGraphDataWithTranslation,
                    hasTooltip: true,
                    tooltipTitle: t('results.summary.crop-rotation'),
                    tooltipUnit: t('constants.hectare-unit'),
                    hasLegend: true,
                    legendFormatter: legendFormatterFunction,
                    labelFormatter: `${cropRotationGraphData?.total_area.toFixed(2)} ${t('constants.hectare-unit')}`,
                    tooltipTotal: Number(cropRotationGraphData?.total_area.toFixed(2)),
                    chartId: 'crop-rotation-graph',
                }),
            });
            return () => {
                cropRotationGraph.dispose();
            };
        }
    }, [cropRotationGraphData, t, legendFormatterFunction]);

    return {
        cropRotationGraphRef,
        isLoading: isLoading || isFetching,
        t,
    };
};
