import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const resultsSeasonSelectionSlice = createSlice({
    name: 'resultsSeason',
    initialState: {
        selectedSeasonId: null as number | null,
    },
    reducers: {
        setSelectedSeasonId(state, action: PayloadAction<number | null>) {
            state.selectedSeasonId = action.payload;
        },
    },
});

export const { setSelectedSeasonId } = resultsSeasonSelectionSlice.actions;
