import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { NumberInput } from '@soil-capital/ui-kit/components';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { useSeedingSoilWorkWidthInputVisibility } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingSoilWorkWidthInput/useSeedingSoilWorkWidthInputVisibility';

type SeedingMachineSoilWorkWidthInputProps = {
    onChange: () => void;
};

export const SeedingSoilWorkWidthInput = ({ onChange }: SeedingMachineSoilWorkWidthInputProps) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplay } = useSeedingSoilWorkWidthInputVisibility();

    if (!context) throw new Error('SeedingSoilWorkWidthInput must be used within a FormProvider');

    return shouldDisplay ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.seeding.question.seed-machinery-width')}
                tooltipTitleKey="encoding-technical-itinerary.seeding.question.seed-machinery-width.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.seeding.question.seed-machinery-width.tooltip.text"
            />
            <NumberInput
                min={0}
                value={context.watch(SEEDING_FORM_INPUT_NAMES.SOIL_WORK_WIDTH) ?? null}
                onChange={(value) => {
                    context.setValue(SEEDING_FORM_INPUT_NAMES.SOIL_WORK_WIDTH, value);
                    onChange();
                }}
                sx={{ maxWidth: 250 }}
                suffix="cm"
            />
        </FormRow>
    ) : (
        <></>
    );
};
