import { makeStyles } from '@soil-capital/ui-kit/style';

export const useGlobalNavStyles = makeStyles()((theme) => ({
    nav: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    links: {
        paddingInlineStart: 0,
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        height: '100%',
        marginTop: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    bottomLink: { '& > :last-child': { marginTop: 'auto' } },
}));
