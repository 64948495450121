import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import { useCanSeeResults } from '@modules/results/hooks/useCanSeeResults';

export const useCanSeeResultsIntroductionGuardAsync = () => {
    const { canSeeResults, isLoading } = useCanSeeResults();

    if (isLoading) return undefined;

    return !!canSeeResults;
};

export const CanSeeResultsIntroductionGuardAsync = (props: GuardT) => {
    const passed = useCanSeeResultsIntroductionGuardAsync();

    return <BaseGuard passed={passed} {...props} renderWhileLoading />;
};
