import { IconArrowSequestration } from '@soil-capital/ui-kit/icons';
import { useSequestrationSummaryStyle } from './SequestrationSummary.style';
import { useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { OrganicFertilizationGraphs } from '../OrganicFertilizationGraphs/OrganicFertilizationGraphs';
import { CoverCropsGraph } from '../CoverCropsGraph/CoverCropsGraph';
import { ResidueManagementGraph } from '../ResidueManagementGraph/ResidueManagementGraph';
import { LegumeGraph } from '../LegumeGraph/LegumeGraph';

export const SequestrationSummary = () => {
    const { classes } = useSequestrationSummaryStyle();
    const { t } = useTranslation();

    return (
        <div className={classes.sequestration}>
            <div className={classes.flexBox}>
                <IconArrowSequestration color="success" />
                <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
                    {t('results.summary.sequestration.title')}
                </Typography>
                <div className={classes.horizontalBar}></div>
            </div>
            <OrganicFertilizationGraphs />
            <div className={classes.grid}>
                <CoverCropsGraph />
                <div className={classes.container}>
                    <ResidueManagementGraph />
                    <LegumeGraph />
                </div>
            </div>
        </div>
    );
};
