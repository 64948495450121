import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useStackBarGraphSkeletonStyles } from './StackBarGraphSkeleton.style';

export const StackBarGraphSkeleton = () => {
    const { classes, cx } = useStackBarGraphSkeletonStyles();
    return (
        <div className={classes.loadingContainerHorizontal}>
            <Skeleton className={classes.skeleton} width="3px" height="100%" />
            <div className={classes.loadingContainerVertical}>
                <div className={classes.loadingBars}>
                    <Skeleton className={classes.skeleton} width={35} height="90%" />
                    <Skeleton className={classes.skeleton} width={35} height="80%" />
                    <Skeleton className={classes.skeleton} width={35} height="50%" />
                    <Skeleton className={classes.skeleton} width={35} height="35%" />
                    <Skeleton className={classes.skeleton} width={35} height="20%" />
                    <Skeleton className={classes.skeleton} width={35} height="20%" />
                    <Skeleton className={classes.skeleton} width={35} height="20%" />
                </div>
                <Skeleton className={cx(classes.skeleton, classes.horizontalBar)} width="100%" height="3px" />
            </div>
        </div>
    );
};
