import { NumberInput } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { useDestructionProductQuantityInputVisibility } from './useDestructionProductQuantityInputVisibility';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type DestructionProductQuantityInputPropsT = {
    onChange: () => void;
};

export const DestructionProductQuantityInput = ({ onChange }: DestructionProductQuantityInputPropsT) => {
    const { t } = useTranslation();
    const { shouldDisplayDestructionProductQuantityInput } = useDestructionProductQuantityInputVisibility();
    const context = useFormContext();

    if (!context) throw new Error('DestructionProductQuantityInput should be used inside a FormProvider');

    return shouldDisplayDestructionProductQuantityInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.destruction-product-quantity')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.destruction-product-quantity.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.destruction-product-quantity.tooltip.text"
            />
            <NumberInput
                min={0}
                value={context.watch(DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_PESTICIDE_QUANTITY) ?? null}
                onChange={(value) => {
                    context.setValue(DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_PESTICIDE_QUANTITY, value);
                    onChange();
                }}
                suffix={t('constants.unit.l-ha')}
                style={{ width: '250px' }}
            />
        </FormRow>
    ) : (
        <></>
    );
};
