import { useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { stackedBarGraphBuilder } from '@modules/results/utils/stackedBarGraphBuilder';
import { useGetTillagePracticeQuery } from '@modules/results/api/graphs.api';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';
import { tillagePracticeGraphTheme } from '@modules/results/utils/theme/tillagePracticeGraphTheme';

export const useTillagePracticeGraphGraphs = () => {
    const tillagePracticeGraphRef = useRef(null);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSeason.selectedSeasonId);
    const { t } = useTranslation();
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();
    const {
        data: tillagePracticeData,
        isLoading: isLoadingTillagePractice,
        isFetching: isFetchingTillagePractice,
    } = useGetTillagePracticeQuery({
        farmSeasonId: selectedSeasonId,
    });

    useEffect(() => {
        if (tillagePracticeData && tillagePracticeGraphRef.current) {
            const tillagePracticeGraph = echarts.init(tillagePracticeGraphRef.current, tillagePracticeGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear })]: tillagePracticeData.reduce((acc, item) => {
                    acc[t(`${item.slug}`)] = parseFloat(Number(item.total_area).toFixed(2));
                    return acc;
                }, {} as Record<string, number>),
            };
            const option = stackedBarGraphBuilder({
                data: formattedData,
                yTitle: t('results.summary.emissions.tillage-practice-y-axis-legend'),
                hasLegend: true,
                hasTooltip: true,
                tooltipTitle: t('results.summary.emissions.tillage-practice-title'),
                tooltipUnit: t('constants.hectare-unit'),
            });
            option.legend.right = '0%';
            tillagePracticeGraph.setOption(option);
            return () => {
                tillagePracticeGraph.dispose();
            };
        }
    }, [tillagePracticeData, harvestYear, t]);

    const hasNoData = useMemo(
        () =>
            tillagePracticeData?.length === 0 &&
            !isLoadingTillagePractice &&
            !isLoadingHarvestYear &&
            !isFetchingTillagePractice,
        [isFetchingTillagePractice, isLoadingHarvestYear, isLoadingTillagePractice, tillagePracticeData],
    );

    return {
        tillagePracticeGraphRef,
        hasNoData,
        isLoadingTillagePractice: isLoadingTillagePractice || isLoadingHarvestYear || isFetchingTillagePractice,
    };
};
