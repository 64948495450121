import { useTranslation } from 'react-i18next';
import { Button } from '@soil-capital/ui-kit/components';
import { Quote } from '@soil-capital/ui-kit/components';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { StepsTimeline } from '@soil-capital/ui-kit';
import { IconEdit, IconChat, IconEye, IconGraph } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { RegenagFaq } from '@modules/results/components/RegenagFaq/RegenagFaq';
import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { useNavigate } from 'react-router-dom';
import { resultRoutesConfig } from '@modules/results/result.routes';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useCanSeeResults } from '@modules/results/hooks/useCanSeeResults';
import { useResultsIntroductionStyle } from './ResultsIntroduction.style';
import { useResultsStepsProgress } from './useResultsStepsProgress';

export const ResultsIntroduction = () => {
    const { t } = useTranslation();
    const { classes } = useResultsIntroductionStyle();
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();
    const { hasAccessToResults, isLoading: isLoadingAccess } = useCanSeeResults();
    const { currentStep, isLoading: isLoadingFarmSeason } = useResultsStepsProgress();

    const handleNextClick = () => navigate(`/${currentSeasonId}/results/${resultRoutesConfig.EARNING}`);

    const isLoading = isLoadingFarmSeason || isLoadingAccess;

    if (isLoading) return null;

    return (
        <PageWithHeader
            header={
                <Button
                    onClick={handleNextClick}
                    className={classes.headerCta}
                    size="large"
                    variant="contained"
                    endIcon={<IconArrowRight />}
                    color="primary"
                    disabled={isLoading || !hasAccessToResults}
                >
                    {t('results.introduction.header.cta')}
                </Button>
            }
            content={
                <>
                    <Typography variant="h1">{t('results.introduction.title')}</Typography>
                    <Quote
                        author={{
                            avatar: '/assets/images/agro-max-avatar.jpeg',
                            name: 'Max,',
                            role: t('results.introduction.quote.author.role'),
                        }}
                    >
                        {t('results.introduction.quote.content')}
                    </Quote>
                    <div className={classes.timelineContainer}>
                        <StepsTimeline
                            steps={[
                                { name: t('results.introduction.timeline.step1'), icon: <IconEdit /> },
                                { name: t('results.introduction.timeline.step2'), icon: <IconEye /> },
                                { name: t('results.introduction.timeline.step3'), icon: <IconChat /> },
                                { name: t('results.introduction.timeline.step4'), icon: <IconGraph /> },
                            ]}
                            progress={currentStep}
                        />
                    </div>
                    <RegenagFaq />
                </>
            }
        />
    );
};
