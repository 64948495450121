import { useRegenagFaqStyle } from '@modules/results/components/RegenagFaq/RegenaqFaq.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import {
    IconArrowSequestration,
    IconArrowEmission,
    IconCropRotation,
    IconTractor,
    IconOrganicFertilisation,
    IconMineralFertilisation,
    IconPlant,
    IconFuel,
} from '@soil-capital/ui-kit/icons';
import { Accordion } from '@soil-capital/ui-kit';

export const RegenagFaq = () => {
    const { t } = useTranslation();
    const { classes } = useRegenagFaqStyle();

    return (
        <div className={classes.container}>
            <Typography className={classes.title} variant="h2">
                {t('results.introduction.faq.title')}
            </Typography>
            <Typography className={classes.description} variant="body2">
                {t('results.introduction.faq.description')}
            </Typography>
            <div className={classes.section}>
                <Typography className={classes.sectionSubtitle} variant="h3">
                    <IconArrowSequestration color="primary" />
                    {t('results.introduction.faq.sequestration.title')}
                </Typography>
                <Accordion
                    className={classes.accordion}
                    Header={
                        <Accordion.Header>
                            <IconCropRotation />
                            <span>{t('results.introduction.faq.sequestration.crop-rotation.title')}</span>
                        </Accordion.Header>
                    }
                    Content={
                        <Accordion.Content className={classes.content}>
                            {t('results.introduction.faq.sequestration.crop-rotation.description')}
                        </Accordion.Content>
                    }
                    defaultExpanded
                />
                <Accordion
                    className={classes.accordion}
                    Header={
                        <Accordion.Header>
                            <IconPlant />
                            <span>{t('results.introduction.faq.sequestration.cover-crops.title')}</span>
                        </Accordion.Header>
                    }
                    Content={
                        <Accordion.Content className={classes.content}>
                            {t('results.introduction.faq.sequestration.cover-crops.description')}
                        </Accordion.Content>
                    }
                />
                <Accordion
                    Header={
                        <Accordion.Header>
                            <IconTractor />
                            <span>{t('results.introduction.faq.sequestration.soil-work.title')}</span>
                        </Accordion.Header>
                    }
                    Content={
                        <Accordion.Content className={classes.content}>
                            {t('results.introduction.faq.sequestration.soil-work.description')}
                        </Accordion.Content>
                    }
                />
                <Accordion
                    Header={
                        <Accordion.Header>
                            <IconOrganicFertilisation />
                            <span>{t('results.introduction.faq.sequestration.organic-fertilisation.title')}</span>
                        </Accordion.Header>
                    }
                    Content={
                        <Accordion.Content className={classes.content}>
                            {t('results.introduction.faq.sequestration.organic-fertilisation.description')}
                        </Accordion.Content>
                    }
                />
            </div>
            <div className={classes.section}>
                <Typography className={classes.sectionSubtitle} variant="h3">
                    <IconArrowEmission color="primary" />
                    {t('results.introduction.faq.emission.title')}
                </Typography>
                <Accordion
                    Header={
                        <Accordion.Header>
                            <IconMineralFertilisation />
                            <span>{t('results.introduction.faq.emission.mineral-fertilisation.title')}</span>
                        </Accordion.Header>
                    }
                    Content={
                        <Accordion.Content className={classes.content}>
                            {t('results.introduction.faq.emission.mineral-fertilisation.description')}
                        </Accordion.Content>
                    }
                />
                <Accordion
                    Header={
                        <Accordion.Header>
                            <IconFuel />
                            <span>{t('results.introduction.faq.emission.fuel.title')}</span>
                        </Accordion.Header>
                    }
                    Content={
                        <Accordion.Content className={classes.content}>
                            {t('results.introduction.faq.emission.fuel.description')}
                        </Accordion.Content>
                    }
                />
            </div>
        </div>
    );
};
