import { useForm } from '@shared/hooks';
import * as z from 'zod';
import { useEffect } from 'react';

import entities, { StaticMachineryT } from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { useTranslation } from 'react-i18next';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { useOperationFormDefaultValues } from '../../hooks/useOperationFormDefaultValues';
import { DEEP_WORK_FORM_INPUT_NAMES, deepWorkFormSchema } from '../../schema/deepWorkFormSchema';
import { getOperationFormApi } from '../../api/getOperationFormApi';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';

export type OperationDeepWorkMachineFormData = z.infer<typeof deepWorkFormSchema.schema>;

export const useOperationDeepWorkMachineForm = () => {
    const { t } = useTranslation();
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: fieldCropId,
        farmSeasonId: seasonId,
    });

    const operation = operationState.getById(operationId);
    const operationTypeId = operation?.operation_type_id;

    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();

    const { staticMachineryState } = entities.staticMachinery.useState({
        country_id: currentFarm?.country_id,
    });

    const staticMachineryLoading = staticMachineryState.isLoading;

    const staticMachineryItems = staticMachineryState.getByOperationTypeId(operationTypeId);

    // Form default values
    const { computeApiDataToMatchSchema, formApiData, isFormLoading, refetchFormData } =
        useOperationFormDefaultValues('deep-work');

    // Form options
    const machineryOptions = staticMachineryItems
        .map((machinery) => ({
            value: machinery.id,
            label: t(`db.machineries.${machinery.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const methods = useForm<typeof deepWorkFormSchema.schema>({
        schema: deepWorkFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(formApiData),
        displaySnackbarOnSchemaError: true,
    });

    const [updateDeepWorkMachine] = getOperationFormApi('deep-work').useUpdate();

    const { watch, handleSubmit, setValue, reset, getValues } = methods;

    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    // Form callbacks
    const onSubmit = useStableDebounce(
        handleSubmit(async (data: OperationDeepWorkMachineFormData) => {
            try {
                await updateDeepWorkMachine({
                    farmSeasonId: seasonId,
                    fieldCropId: fieldCropId,
                    operationId: operationId,
                    body: {
                        ...data,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    const selectedMachineryId = watch(DEEP_WORK_FORM_INPUT_NAMES.MACHINERY_ID);
    const selectedMachinery: StaticMachineryT | undefined = staticMachineryItems.find(
        (machinery) => machinery.id === selectedMachineryId,
    );

    /**
     * set the default values for the new machinery
     */
    const changeMachineryRelatedInputs = (newMachineryId: number) => {
        const newMachinery: StaticMachineryT | undefined = staticMachineryItems.find(
            (machinery) => machinery.id === newMachineryId,
        );

        if (!newMachinery) {
            return;
        }
        setValue(
            DEEP_WORK_FORM_INPUT_NAMES.DEPTH,
            newMachinery.depth ? Number(newMachinery.depth) : newMachinery.depth,
        );
        if (newMachinery.slug !== STATIC_MACHINERY_SLUGS.STRIP_TILL) {
            setValue(DEEP_WORK_FORM_INPUT_NAMES.SOIL_WORK_WIDTH, null);
            setValue(DEEP_WORK_FORM_INPUT_NAMES.INTERROW, null);
        }
    };

    return {
        isFormLoading: isFormLoading || currentFarmLoading || staticMachineryLoading,
        machineryOptions,
        setValue,
        watch,
        onSubmit,
        methods,
        selectedMachinery,
        refetchFormData,
        getFormValues: getValues,
        changeMachineryRelatedInputs,
    };
};
