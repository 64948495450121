import { IconSeed } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../GraphContainer/GraphContainer';
import { useResidueManagementStyles } from './ResidueManagement.style';
import { useResidueManagementGraphData } from './useResidueManagementGaph.data';

export const ResidueManagementGraph = () => {
    const { residueManagementGraphRef, isLoadingResidueManagement, t, hasNoData } = useResidueManagementGraphData();
    const { classes } = useResidueManagementStyles();
    return (
        <GraphContainer
            title={t('results.summary.sequestration.residue-management')}
            isLoading={isLoadingResidueManagement}
            className={classes.marginBottom}
            titleIcon={<IconSeed />}
            hasBorders
            type="pie"
            orientation="vertical"
            hasNoData={hasNoData}
        >
            <div ref={residueManagementGraphRef} className={classes.graphContainer} id="residue-management-graph"></div>
        </GraphContainer>
    );
};
