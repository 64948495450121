import { NumberInput } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { WEEDING_MACHINE_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/weedingMachineFormSchema';

type WeedingMachinePassageInputPropsT = {
    onChange: () => void;
    disabled?: boolean;
};

export const WeedingMachinePassageInput = ({ onChange, disabled }: WeedingMachinePassageInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();

    if (!context) throw new Error('This component should be used inside a FormProvider');

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.weeding-machine.passage')}
                tooltipTitleKey="encoding-technical-itinerary.weeding-machine.passage-tooltip-title"
                tooltipTextKey="encoding-technical-itinerary.weeding-machine.passage-tooltip-text"
            />
            <NumberInput
                min={0}
                incrementationStep={1}
                value={context.watch(WEEDING_MACHINE_FORM_INPUT_NAMES.PASSAGE) ?? null}
                onChange={(value) => {
                    context.setValue(WEEDING_MACHINE_FORM_INPUT_NAMES.PASSAGE, value);
                    onChange();
                }}
                style={{ width: '250px' }}
                disabled={disabled}
            />
        </FormRow>
    );
};
